<template>
    <div :class="{ autoScaleMob: isMobile }">
        <label class="CheckView">
            <input type="checkbox" v-model="innerValue"/>
            <svg-view name="cb-checked"></svg-view>
            <svg-view name="cb-unchecked"></svg-view>
            {{caption}}
            <span class="captionText" v-html="captionText"></span>
        </label>
    </div>
</template>

<script>
    export default {
        name: "InputView",
        props: {
            value: {type: Boolean, default: false},
            caption: {type: String, default: ""},
            captionText: {type: String, default: ""},
        },
    };
</script>

<style scoped lang="less">
    @import "../style.less";

    .CheckView {
        font: 500 16px/19px @Roboto;
        color: @color-black;
        cursor: pointer;
    }

    input {
        display: none;
    }

    svg {
        width: 19px;
        height: 18px;
        margin-bottom: -3px;
        margin-top: -10px;
        margin-right: 10px;
    }

    input:checked ~ .svg--cb-unchecked {
        display: none;
    }

    input:not(:checked) ~ .svg--cb-checked {
        display: none;
    }

    .autoScaleMob {
        box-sizing: border-box;
        padding-top: 13px;
    }

    .captionText {

        font: normal 14px/17px @MysLC;
        color: @color-black;
        cursor: pointer;
    }
    /deep/ a {
        color: @color-primary;

    }
</style>
