<template>
  <div class="FilterLinks" :class="{ isMobile, isDesktop }" ref="root">
    <div class="Content" v-if="render && blocks.length">
      <div class="FilterLinks-content">
        <div class="title">Быстрые ссылки</div>
        <div class="items">
          <div v-for="block in addictedBlocks" :key="block.field" class="item">
            <span class="defaulthover" v-if="!block.selectedSelections" @click="openPopup(block)">{{ block.title }}</span>
            <span v-if="block.selectedSelections" class="FilterLinks-selectedValue">
              <span class="defaulthover" @click="openPopup(block)">{{ block.selectedSelections }}</span>
              <a class="defaulthover" :href="block.rootlink">✕</a>
            </span>
          </div>
        </div>
      </div>

      <div class="FilterLinks-popup popup-center no-scroll visible" v-show="showPopup">
        <div class="popup-outer">
          <div class="popup-content" :data-field="(currentBlock || {}).field">
            <svg-view class="defaulthover" @click.native="showPopup = false" name="close" />

            <div v-for="block in blocks" :key="block.field" v-show="block.field == (currentBlock || {}).field">
              <div class="h1">{{ block.title }}</div>
              <div class="items" v-if="block.field == (currentBlock || {}).field">
                <template v-for="item in currentSelections">
                  <a v-if="item.type == 'item'" :key="item.url" class="item defaulthover" :href="item.url">{{ item.title }}</a>
                  <div v-if="item.type == 'header'" :key="item.url" class="itemheader">{{ item.title }}</div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MaskedInputView from "../common/components/MaskedInputView.vue";
import isEqual from "lodash/isEqual";

/*
('okrug', u'Округ'),
('area', u'Район'),
('street', u'Улица'),
('metro', u'Метро'),
('village', u'Населенный пункт'),
('highway', u'Шоссе'),
*/

export default {
  components: { MaskedInputView },
  computed: {
    blocks() {
      return this.state?.blocks ?? [];
    },
    addictedBlocks() {
      return this.blocks.map((block) => {
        const values = this.params?.[block.field] ?? [];
        block.selectedSelections = values.map((v) => block.selections?.find((s) => s.val == v)?.title).join(", ");
        return block;
      });
    },
    currentSelections() {
      let items = this.currentBlock?.selections ?? [];
      items = items.filter((i) => i.val != this.params[this.currentBlock?.field] && !(this.params[this.currentBlock?.field]?.indexOf?.(i.val) >= 0));
      //sel.val != params[currentBlock.field]
      return _.sortBy(items, "title");
    },
  },

  data: () => ({
    render: false,
    showPopup: false,

    params: null,
    state: null,

    currentBlock: null,
  }),

  watch: {
    params(newVal, oldVal) {
      if (newVal && !this.loadingData && !isEqual(newVal, oldVal)) {
        if (newVal.cont_type != "2") {
          this.render = false;
          return;
        }
        this.render = true;
        this.loadingData = true;
        axios
          .post("/api_v2/filter_links/filter_links_data/", newVal)
          .then((response) => {
            if (response.data) {
              this.state = response.data;
              console.log("state", this.state);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      }
    },
  },

  mounted() {},

  methods: {
    openPopup(block) {
      this.currentBlock = block;
      this.showPopup = true;
    },
    setParams(params) {
      console.log("FilterLinksApp setParams", params);
      console.log(JSON.stringify(params));
      this.params = params;
    },
  },
};
</script>

<style scoped lang="less">
@import "../common/style.less";

.FilterLinks-content {
  text-align: center;
  color: @color-primary-dark;

  .FilterLinks-selectedValue {
    a {
      color: red;
      text-decoration: none;
    }
    .itemheader {
    }
  }
}

.FilterLinks-popup {
  .h1 {
    text-align: center;
  }
  .items {
    text-align: left;
  }
  .item {
    color: @color-primary-dark;
    display: inline;
    white-space: nowrap;

    &::after {
      content: " ";
    }
  }
}

.FilterLinks.isDesktop {
  .FilterLinks-content {
    padding: 70px 30px 30px;
    .title {
      font: 500 16px/19px @Roboto;
      color: #3c4043;
    }
    .items {
      padding-top: 20px;
    }
    .item {
      font: 500 14px/19px @Roboto;
      display: inline-block;
      padding: 3px 10px;
    }
  }

  &.popup-content {
    &[data-field="metro"] {
      min-width: 1000px !important;
    }
    max-height: 99vh;
    overflow: auto;
    padding: 0;
    min-height: 200px;
    text-align: left;
  }

  .popup-outer {
    overflow: auto;
  }

  .FilterLinks-popup {
    .h1 {
      font: 400 36px/42px @MysLC;
    }
    .items {
      padding: 30px 40px 0;
    }
    .item {
      font: 500 14px/30px @Roboto;
      padding: 13px 0;
      margin-right: 10px;
    }
    .itemheader {
      font: 400 26px/32px @MysLC;
      margin-top: 1em;
    }
  }
}

.FilterLinks.isMobile {
  .FilterLinks-content {
    padding: 50px 0;
    .title {
      font: 500 calc(16px * @mobileScale) / calc(19px * @mobileScale) @Roboto;
    }
    .items {
      padding-top: 34px;
    }
    .item {
      font: 500 calc(16px * @mobileScale) / calc(19px * @mobileScale) @Roboto;
      display: inline-block;
      padding: 3px 10px;
    }
    .itemheader {
      font: 400 calc(26px * @mobileScale) / calc(32px * @mobileScale) @MysLC;
      margin-top: 1em;
    }
  }

  .popup-center {
    padding: 100px 0;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    overflow: auto;
  }

  .FilterLinks-popup {
    .h1 {
      font: 400 calc(36px * @mobileScale) / calc(42px * @mobileScale) @MysLC;
    }
    .items {
      padding: 30px 60px 0;
    }
    .item {
      font: 500 calc(14px * @mobileScale) / calc(30px * @mobileScale) @Roboto;
      padding: 13px 0;
      margin-right: 10px;
    }
  }
}
.svg--close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  padding: 5px;
  z-index: 1000;
  fill: @color-primary;
  .FilterLinks.isMobile & {
    transform: scale(@mobileScale);
    transform-origin: 100% 0;
    top: @mobileScale * 16px;
    right: @mobileScale * 16px;
  }
}
</style>
