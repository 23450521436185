import Vue from "vue";

import validationComponent from "./mixins/validationComponent";
Vue.mixin(validationComponent);

import "vue-multiselect/dist/vue-multiselect.min.css";
import SelectView from "./components/SelectView.vue";
import MultiSelectView from "./components/MultiSelectView.vue";
import SvgView from "./components/SvgView.vue";
import RangeSlider from "./components/RangeSlider.vue";
import InputView from "./components/InputView.vue";
import CheckView from "./components/CheckView.vue";
import Btn from "./components/Button.vue";
import MaskedInputView from "./components/MaskedInputView.vue";

Vue.mixin({
  components: {
    SelectView,
    MultiSelectView,
    SvgView,
    RangeSlider,
    InputView,
    CheckView,
    Btn,
    MaskedInputView,
  },
  methods: {
    static(url) {
      return BUNDLE_STATIC_URL + url;
    },
  },
});
