var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"root",staticClass:"CatalogFormApp left-popup popup-form -visible popup-catalog-vue",class:{ isMobile: _vm.isMobile, isDesktop: _vm.isDesktop, visible: _vm.visible, thanks: _vm.thanks },attrs:{"id":"catalog-new"}},[(_vm.needRender)?_c('div',[_c('div',{staticClass:"back"}),_vm._v(" "),_c('span',{staticClass:"close-popup isvg isvg-popup-close defaulthover",on:{"click":function($event){return _vm.toggle(false)}}}),_vm._v(" "),_c('div',{staticClass:"h1"},[_vm._v("Запрос каталога новостроек Москвы")]),_vm._v(" "),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [(!_vm.thanks)?_c('div',{staticClass:"popup-content"},[_c('div',{staticClass:"popup-content-viewport"},[_c('div',{staticClass:"popup-content-viewport-inner linear_form"},[_c('div',{staticClass:"description"},[_vm._v("Оставьте свои контактные данные и мы отправим вам электронную версию каталога новостроек.")]),_vm._v(" "),_c('div',{staticClass:"node clear-block node-webform"},[_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-view',{attrs:{"placeholder":"Имя","errors":errors},model:{value:(_vm.result.name),callback:function ($$v) {_vm.$set(_vm.result, "name", $$v)},expression:"result.name"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":{ required: true, phone: { completed: _vm.result.phoneCompleted } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('masked-input-view',{attrs:{"placeholder":"Телефон","mask":"\\+\\7 (111) 111-11-11","errors":errors},on:{"completed":function($event){_vm.result.phoneCompleted = $event}},model:{value:(_vm.result.phone),callback:function ($$v) {_vm.$set(_vm.result, "phone", $$v)},expression:"result.phone"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-view',{attrs:{"placeholder":"Email","errors":errors},model:{value:(_vm.result.email),callback:function ($$v) {_vm.$set(_vm.result, "email", $$v)},expression:"result.email"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-view',{staticClass:"needsclickroot",attrs:{"placeholder":"Способ связи","options":_vm.stdConnectionMethodsChoices,"errors":errors},model:{value:(_vm.result.connectionMethod),callback:function ($$v) {_vm.$set(_vm.result, "connectionMethod", $$v)},expression:"result.connectionMethod"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('btn',{staticClass:"needsclick",attrs:{"type":"button","loading":_vm.submitting,"pulse":valid,"title":"Отправить"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-agreement",class:{ 'u-ErrorView': errors.length > 0 }},[_c('label',{staticClass:"nostyle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.result.comfirmSend),expression:"result.comfirmSend"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.result.comfirmSend)?_vm._i(_vm.result.comfirmSend,null)>-1:(_vm.result.comfirmSend)},on:{"change":function($event){var $$a=_vm.result.comfirmSend,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.result, "comfirmSend", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.result, "comfirmSend", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.result, "comfirmSend", $$c)}}}}),_vm._v(" Нажимая кнопку \"Отправить\", вы подтверждаете согласие на обработку персональных данных в соответствии с\n                      "),_c('a',{staticClass:"underline",attrs:{"target":"_blank","href":"https://www.welhome.ru/disclaimer"}},[_vm._v("\"Положением об обработке персональных данных ООО \"ВЭЛХОМ\"")])])])]}}],null,true)})],1)])])])]):_vm._e()]}}],null,false,973084756)}),_vm._v(" "),(_vm.thanks)?_c('div',{staticClass:"popup-thanks"},[_c('div',{staticClass:"popup-thanks-inner"},[_c('div',{staticClass:"header"},[_vm._v("Спасибо!")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('btn',{staticClass:"needsclick",attrs:{"type":"button","pulse":false,"title":"Закрыть"},on:{"click":function($event){return _vm.toggle(false)}}})],1)]):_vm._e()],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text"},[_vm._v("Ваша заявка отправлена."),_c('br'),_vm._v("Мы свяжемся с вами в ближайшее время.")])}]

export { render, staticRenderFns }