var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ autoScaleMob: _vm.isMobile && _vm.useAutoScaleMob }},[_c(!!_vm.href ? 'a' : 'button',{tag:"component",staticClass:"Btn",class:[
      {
        isDesktop: _vm.isDesktop,
        isMobile: _vm.isMobile,
        blocked: _vm.blocked || _vm.loading,
        disabled: _vm.disabled,
        pulse: _vm.pulse && !_vm.blocked && !_vm.loading && !_vm.disabled,
      },
      _vm.btnClasses ],attrs:{"type":!_vm.href ? _vm.href : null,"href":_vm.href},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.loading)?_c('span',{staticClass:"spinner"}):(!!_vm.title)?_c('span',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }