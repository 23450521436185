<template>
  <div class="Callback" :class="{ isMobile, isDesktop }">
    <svg-view v-if="isDesktop" class="CallbackShow" @click.native="show" name="callback" />
    <div class="Backdrop" v-if="visible"></div>
    <div class="Content" v-if="visible">
      <svg-view class="defaulthover" @click.native="hide" name="close" />
      <callback-select v-if="!showForm" @call="showForm = true" />
      <callback-form v-if="showForm" @back="showForm = false" @success="hide" />
    </div>
  </div>
</template>

<script>
import CallbackForm from "./CallbackForm.vue";
import CallbackSelect from "./CallbackSelect.vue";
export default {
  components: { CallbackSelect, CallbackForm },
  data: () => ({
    visible: false,
    showForm: false,
  }),
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
  mounted() {
    //this.visible = true;
    $('.bottom-flow-buttons .call').click(() => this.show())
  },
  watch: {
    visible(newVal, oldVal) {
      setTimeout(
        () => {
          $("body").toggleClass("overflowhidden", newVal);
        },
        newVal ? 1 : 0
      );
    },
  },
};
</script>

<style scoped lang="less">
@import "../common/style.less";

.CallbackShow {
  fill: @color-black;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 30px;
  bottom: 50px;
  transition: fill 0.2s;
  cursor: pointer;

  &:hover {
    fill: @color-primary;
  }
}

.Callback.isDesktop .Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.Content {
  position: fixed;
  z-index: 9999999999;
  top: 0;
  bottom: 0;
  right: 0;
  width: 416px;
  background-color: #fff;
  padding: 10px 20px;

  .Callback.isMobile & {
    left: 0;
    width: auto;
  }
}

.svg--close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  padding: 5px;
  z-index: 1000;
  fill: @color-primary;
  .Callback.isMobile & {
    transform: scale(@mobileScale);
    transform-origin: 100% 0;
    top: @mobileScale * 16px;
    right: @mobileScale * 16px;
  }
}
</style>
