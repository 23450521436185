<template>
  <form class="QuestionnaireApp" :class="{ isMobile, isDesktop }" ref="root" id="questionnaire-popup">
    <div class="Content form" v-if="needRender">
      <div class="popup-center popup-center-questionnaire no-scroll visible">
        <div class="popup-outer">
          <div class="popup-content">
            <div v-if="!finished" class="questionnaire" :class="{ current: !finished }">
              <div class="comments">
                <div class="comment current" v-if="!!currentQuestion">
                  <img
                    v-if="currentQuestion.photo"
                    class="comment-photo"
                    :src="`/static/front/img/questionnaire/${currentQuestion.photo}`"
                    :srcset="`/static/front/img/questionnaire/${currentQuestion.photo2x} 2x`"
                  />
                  <div v-html="currentQuestion.comment"></div>
                  <!--<div>{{currentIndex}} {{result}}</div>-->
                </div>
              </div>
              <div class="questions-outer">
                <div class="questions">
                  <div class="questions-inner">
                    <div class="question current">
                      <div class="question-number">Вопрос {{ currentIndex + 1 }} из {{ questionnaireData.questionnaire.questions.length }}</div>
                      <div class="question-question">{{ currentQuestion.question }}</div>
                      <div v-if="currentQuestion.tp == 'radio'" class="question-answers">
                        <label v-for="answer in currentQuestion.answers" :key="answer.id" class="question-answer needsclick" :for="`question-${currentQuestion.id}-${answer.id}`">
                          <input class="needsclick" type="radio" :id="`question-${currentQuestion.id}-${answer.id}`" :value="`${answer.id}`" v-model="result.formData[currentQuestion.id]" /><i
                            class="svg isvg-filters-check-white"
                          ></i>
                          <span class="needsclick">{{ answer.text }}</span>
                          <input v-if="answer.custom == 'input'" class="needsclick" type="text" v-model="result.formData[currentQuestion.id + '-custom']" />
                        </label>
                      </div>
                      <div v-if="currentQuestion.tp == 'text'" class="question-answers">
                        <textarea class="question-answer-textarea needsclick" v-model="result.formData[currentQuestion.id]"></textarea>
                      </div>
                      <div v-if="currentQuestion.tp == 'checkbox'" class="question-answers">
                        <label v-for="answer in currentQuestion.answers" :key="answer.id" class="question-answer needsclick" :for="`question-${currentQuestion.id}-${answer.id}`">
                          <input
                            :disabled="!result.formData[currentQuestion.id][answer.id] && currentAnswersCount >= 3"
                            class="needsclick"
                            type="checkbox"
                            :id="`question-${currentQuestion.id}-${answer.id}`"
                            :value="`${answer.id}`"
                            v-model="result.formData[currentQuestion.id][answer.id]"
                          /><i class="svg isvg-filters-check-white"></i>
                          <span class="needsclick">{{ answer.text }}</span>
                          <input
                            :disabled="!result.formData[currentQuestion.id][answer.id] && currentAnswersCount >= 3"
                            @focus="result.formData[currentQuestion.id][answer.id] = true"
                            v-if="answer.custom == 'input'"
                            class="needsclick"
                            type="text"
                            v-model="result.formData[currentQuestion.id][answer.id + '-custom']"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="buttons">
                    <button type="button" class="brown_button btn-skip needsclick" @click="next">Пропустить</button>
                    <button type="button" class="brown_button btn-next needsclick" :disabled="!currentAnswersCount" @click="next">Далее</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="finished" class="finishForm" :class="{ current: finished }">
              <div class="finishForm-text">{{ questionnaireData.questionnaire.finishText.replace("###", finishCount) }}</div>
              <validation-observer v-slot="{ handleSubmit, valid }">
                <div class="finishForm-fields">
                  <div class="finishForm-fields-inner">
                    <div class="FinishPhone">
                      <validation-provider :rules="{ required: true, phone: { completed: phoneCompleted } }" v-slot="{ errors }">
                        <masked-input-view v-model="result.phone" placeholder="Ваш телефон" mask="\+\7 (111) 111-11-11" @completed="phoneCompleted = $event" :errors="errors" />
                      </validation-provider>
                    </div>
                    <div class="FinishConnection">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <select-view
                          class="needsclickroot"
                          placeholder="Способ связи"
                          v-model="result.connectionMethod"
                          :options="questionnaireData.questionnaire.methods.filter((m) => !!m).map((m) => ({ id: m, label: m }))"
                          :errors="errors"
                        />
                      </validation-provider>
                    </div>
                  </div>
                  <btn type="button" class="FinishBtn needsclick" :loading="submitting" :pulse="valid" title="Перезвоните мне" @click.prevent="handleSubmit(submit)" />
                </div>

                <validation-provider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
                  ><div class="form-agreement" :class="{ 'u-ErrorView': errors.length > 0 }">
                    <label class="nostyle"
                      ><input type="checkbox" v-model="result.comfirmSend" /> Нажимая кнопку "Отправить", вы подтверждаете согласие на обработку персональных данных в соответствии с
                      <a class="underline" target="_blank" href="https://www.welhome.ru/disclaimer">"Положением об обработке персональных данных ООО "ВЭЛХОМ"</a></label
                    >
                  </div>
                </validation-provider>
              </validation-observer>
            </div>
            <span class="close isvg isvg-popup-close defaulthover" @click="toggle(false)"></span>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import MaskedInputView from "../common/components/MaskedInputView.vue";

export default {
  components: { MaskedInputView },
  computed: {
    needRender() {
      return this.visible && !!this.questionnaireData;
    },
    currentQuestion() {
      return !!this.questionnaireData && this.questionnaireData.questionnaire.questions[this.currentIndex];
    },
    currentAnswersCount() {
      if (this.currentQuestion.tp != "checkbox") return !!this.result.formData[this.currentIndex] ? 1 : 0;
      return Object.values(this.result.formData[this.currentIndex]).filter((x) => x === true).length;
    },
  },

  data: () => ({
    render: false,
    visible: false,
    questionnaireData: null,
    loadingData: false,

    submitting: false,

    currentIndex: 0,

    finished: false,
    finishCount: 7,
    phoneCompleted: null,

    result: {
      formData: [],
      connectionMethod: "",
      phone: "",
      comfirmSend: false,
    },
  }),

  watch: {
    visible(newVal, oldVal) {
      if (newVal && !this.questionnaireData && !this.loadingData) {
        this.loadingData = true;
        axios.get("/api_v2/questionnaire/questionnaire_data/").then((response) => {
          this.reset(response.data);
          this.questionnaireData = response.data;
        });
      }
    },
  },

  mounted() {
    $("body").on("click", ".show-popup[data-popup=popup-center-questionnaire-vue]", () => {
      this.toggle(true);
    });
    //$(".v2leftmenu .icon-left-menu").click(() => this.toggle(true));
    //$(".v2leftmenu .icon-left-menu-close").click(() => this.toggle(false));
    //setTimeout(() => this.toggle(true),0);
    this.showSuggest();

    if (window.questionnaireData) {
      let data = { questionnaire: window.questionnaireData };
      this.reset(data);
      this.questionnaireData = data;
    }

    const urlParams = new URLSearchParams(window.location.search);
    this.toggle(urlParams.get("questionnaire") == "welhome");
  },

  methods: {
    toggle(visible) {
      this.visible = visible;
    },
    next() {
      this.currentIndex += 1;
      if (this.currentIndex >= 7) {
        this.finished = true;
      }

      $("*", this.$refs.root).scrollTop(0);
    },
    randomCount() {
      const counts = [5, 6, 7, 8, 9, 10];
      return counts[Math.floor(Math.random() * counts.length)];
    },

    reset(questionnaireData) {
      this.finishCount = this.randomCount();
      this.submitting = false;
      this.finished = false;
      this.currentIndex = 0;
      this.result.formData = (questionnaireData || this.questionnaireData).questionnaire.questions.map((q) => {
        switch (q.tp) {
          case "checkbox":
            return {};
          case "radio":
          case "text":
            return null;
          default:
            return null;
        }
      });
    },

    submit() {
      this.submitting = true;
      axios
        .post("/api_v2/questionnaire/submit/", this.result)
        .then(() => {
          this.visible = false;
          this.reset();

          var dataLayer = (window.dataLayer = window.dataLayer || []);
          var evt = {
            event: "otpravka-formi-" + this.$refs.root.id,
            eventCategory: "lead",
          };
          dataLayer.push(evt);
          console.log({ evt });
        })
        .catch(() => {
          this.submitting = false;
          alert("Не удалось отправить заявку, попробуйте позже!");
        });
    },

    showSuggest() {
      if ($(".isvg-left-menu-questionnaire").length && !$.cookie("questionnaire-shown")) {
        $.cookie("questionnaire-shown", 1, { path: "/" });
        $.cookie("questionnaire-shown", 1, { path: "/", domain: ".welhome.ru" });
        $(window).load(function () {
          $(".isvg-left-menu-questionnaire").addClass("accent");
          setTimeout(function () {
            $(".isvg-left-menu-questionnaire").removeClass("accent");
          }, 5300);
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "../common/style.less";

.FinishPhone {
  width: 330px;
  margin-right: 16px;
}
.FinishConnection {
  width: 192px;
  .isDesktop & {
    margin-right: 16px;
  }

  .isMobile & {
    width: 250px;
  }
}
.isDesktop .FinishBtn {
  width: 203px;
}
.isMobile .finishForm-fields-inner {
  margin-bottom: 16px;
}
</style>
