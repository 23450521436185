<template>
  <form class="CatalogFormApp left-popup popup-form -visible popup-catalog-vue" id="catalog-new" ref="root" :class="{ isMobile, isDesktop, visible, thanks }">
    <div v-if="needRender">
      <div class="back"></div>
      <span class="close-popup isvg isvg-popup-close defaulthover" @click="toggle(false)"></span>
      <div class="h1">Запрос каталога новостроек Москвы</div>

      <validation-observer v-slot="{ handleSubmit, valid }">
        <div class="popup-content" v-if="!thanks">
          <div class="popup-content-viewport">
            <div class="popup-content-viewport-inner linear_form">
              <div class="description">Оставьте свои контактные данные и мы отправим вам электронную версию каталога новостроек.</div>
              <div class="node clear-block node-webform">
                <div class="row">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input-view v-model="result.name" placeholder="Имя" :errors="errors" />
                  </validation-provider>
                </div>
                <div class="row">
                  <validation-provider :rules="{ required: true, phone: { completed: result.phoneCompleted } }" v-slot="{ errors }">
                    <masked-input-view v-model="result.phone" placeholder="Телефон" mask="\+\7 (111) 111-11-11" @completed="result.phoneCompleted = $event" :errors="errors" />
                  </validation-provider>
                </div>

                <div class="row">
                  <validation-provider rules="required|email" v-slot="{ errors }">
                    <input-view v-model="result.email" placeholder="Email" :errors="errors" />
                  </validation-provider>
                </div>

                <div class="row">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <select-view class="needsclickroot" placeholder="Способ связи" v-model="result.connectionMethod" :options="stdConnectionMethodsChoices" :errors="errors" />
                  </validation-provider>
                </div>

                <div class="row">
                  <btn type="button" class="needsclick" :loading="submitting" :pulse="valid" title="Отправить" @click.prevent="handleSubmit(submit)" />
                </div>

                <div class="row">
                  <validation-provider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                    <div class="form-agreement" :class="{ 'u-ErrorView': errors.length > 0 }">
                      <label class="nostyle"
                        ><input type="checkbox" v-model="result.comfirmSend" /> Нажимая кнопку "Отправить", вы подтверждаете согласие на обработку персональных данных в соответствии с
                        <a class="underline" target="_blank" href="https://www.welhome.ru/disclaimer">"Положением об обработке персональных данных ООО "ВЭЛХОМ"</a></label
                      >
                    </div>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>

      <div class="popup-thanks" v-if="thanks">
        <div class="popup-thanks-inner">
          <div class="header">Спасибо!</div>
          <div class="text">Ваша заявка отправлена.<br />Мы свяжемся с вами в ближайшее время.</div>
          <btn type="button" class="needsclick" :pulse="false" title="Закрыть" @click="toggle(false)" />
        </div>
      </div>
      <!--<span class="close isvg isvg-popup-close defaulthover" @click="toggle(false)"></span>-->
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  computed: {
    needRender() {
      return this.visible;
    },
    completed() {
      return this.result.name && this.result.email && this.result.phone && this.result.comfirmSend && this.result.connectionMethod;
    },
  },

  data: () => ({
    visible: false,
    submitting: false,
    thanks: false,

    /*result: {
                name: '123',
                email: 'qwe@qwe.com',
                phoneCompleted: true,
                phone: '123123',
                comfirmSend: true,
                connectionMethod: 'Telegram',
            }*/
    result: {
      name: "",
      email: "",
      phoneCompleted: false,
      phone: "",
      comfirmSend: false,
      connectionMethod: "",
    },
  }),

  mounted() {
    $(this.$refs.root)
      .on("toggle_visible", () => {
        this.toggle(!this.visible);
      })
      .on("hide", () => {
        this.toggle(false);
      });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("form") == "catalog") {
      this.toggle(true);

      if (this.isMobile) {
        $("body").addClass("show-menu-left");
      }
    }
  },

  methods: {
    toggle(visible) {
      if (!this.visible && visible) {
        this.reset();
      }
      this.visible = visible;
    },

    reset() {
      this.submitting = false;
      this.thanks = false;
    },

    submit() {
      this.submitting = true;
      const formData = new FormData();

      let data = {
        form_cmd: "form_request_for_selection",
        name: this.result.name,
        phone: this.result.phone,
        mail: this.result.email,
        department: 1,
        op_type: 0,
        text: "заявка на каталог в pdf!",
        method: this.result.connectionMethod,
        agreement: "on",
      };
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      axios
        .post("/api/submit_form/53/", formData, {
          headers: {
            "Content-Type": "form-data",
          },
        })
        .then(() => {
          //this.visible = false;
          //this.reset();
          this.thanks = true;

          var dataLayer = (window.dataLayer = window.dataLayer || []);
          var evt = {
            event: "otpravka-formi-" + this.$refs.root.id,
            eventCategory: "lead",
          };
          dataLayer.push(evt);
          console.log({ evt });
        })
        .catch(() => {
          alert("Не удалось отправить заявку, попробуйте позже!");
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
@import "../common/style.less";

.row {
  margin-bottom: 16px;
}
</style>
