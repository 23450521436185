var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"GeoSelectViewWrapper",class:{
    isMobile: _vm.isMobile,
    isDesktop: _vm.isDesktop,
    isOpen: _vm.isOpen,
    needScaleFontOnMob: _vm.isMobile && _vm.isIOS,
  }},[(_vm.isMobile)?_c('div',{staticClass:"GeoSelectViewWrapperInnerDummy autoScaleMobZoom",on:{"click":function () {
        this$1.isOpen = true;
        this$1.$refs.select.open = true;
      }}},[_c('div',{staticClass:"GeoSelectView u-InputMarginView border"},[_c('div',{staticClass:"GeoSelectViewInput v-select vs--searchable",attrs:{"dir":"auto"}},[_c('div',{staticClass:"vs__dropdown-toggle",attrs:{"id":"vs15__combobox","role":"combobox","aria-expanded":"false","aria-owns":"vs15__listbox","aria-label":"Search for option"}},[_c('div',{staticClass:"vs__selected-options"},[_c('span',{staticClass:"span vs__search",class:{ noOptions: !_vm.innerValue.length }},[_vm._v(_vm._s(!_vm.innerValue.length ? _vm.placeholder : _vm.innerValue.map(function (v) { return v.label; }).join(", ")))])]),_vm._v(" "),_c('div',{staticClass:"vs__actions"},[_c('span',{staticClass:"custom-open-indicator"},[_c('svg',{staticClass:"open_indicator",attrs:{"width":"14","height":"9","viewBox":"0 0 14 9","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1L7 7L13 1","stroke-width":"2"}})])])])])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"GeoSelectViewWrapperInner",class:{ autoScaleMobZoom: _vm.isMobile },style:({ display: !_vm.isMobile || _vm.isOpen ? null : 'none' })},[(_vm.isMobile && _vm.isOpen)?_c('div',{staticClass:"GeoSelectViewMobClose",on:{"click":function () {
          this$1.$refs.select.open = false;
          this$1.isOpen = false;
        }}},[_c('svg-view',{staticClass:"svg-close",attrs:{"name":"close-geo"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"GeoSelectView u-InputMarginView",class:[_vm.modes.map(function (m) { return ("GeoSelectView--" + m); }), { 'u-ErrorView': _vm.hasError, border: _vm.border }]},[(!!_vm.title)?_c('label',{staticClass:"GeoSelectViewLabel"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_vm._v(" "),_c('v-select',{ref:"select",staticClass:"GeoSelectViewInput",attrs:{"autoscroll":false,"options":_vm.currentOptions,"placeholder":_vm.placeholder,"max-height":_vm.maxHeight,"clearable":false,"searchable":true,"multiple":_vm.multiple,"clearSearchOnSelect":true,"clearSearchOnBlur":function () { return true && !_vm.debugMenu; },"closeOnSelect":!_vm.isMobile,"selectable":function (option) { return !option.header; },"filter":_vm.filter,"getOptionKey":function (option) { return 'aa' + option.id + option.label; },"transition":_vm.isMobile ? '' : 'vs__fade'},on:{"search:blur":function () {
            _vm.searchQuery = _vm.debugMenu ? _vm.searchQuery : '';
            if (this$1.isMobile) {
              this$1.$refs.select.open = true;
            } else {
            }
          },"search:focus":function($event){_vm.isOpen = true},"open":function($event){_vm.isOpen = true},"close":function($event){_vm.isOpen = false}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [(!option.header)?_c('div',{on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleOption(option)}}},[_c('span',{staticClass:"icomoon icomoon-type",class:'icomoon-geo-' + option.type + (option.type == 'jk' ? '-' + _vm.direction : ''),style:({ color: option.iconColor })}),_vm._v(" "),_c('span',{staticClass:"GeoSelectViewOption",domProps:{"innerHTML":_vm._s(option.label)}}),_vm._v(" "),_c('span',{staticClass:"icomoon icomoon-checked icomoon-option-selected-indicator"})]):_vm._e(),_vm._v(" "),(option.header)?_c('div',[_c('span',{staticClass:"GeoSelectViewOptionHeader",domProps:{"innerHTML":_vm._s(option.label)}})]):_vm._e()]}},{key:"search",fn:function(ref){
          var attributes = ref.attributes;
          var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",class:{ noOptions: !_vm.innerValue.length },on:{"input":function($event){_vm.searchQuery = $event.target.value},"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==8){ return null; }return _vm.innerValue.pop()}}},'input',attributes,false),events))]}},{key:"no-options",fn:function(ref){
          var search = ref.search;
          var searching = ref.searching;
          var loading = ref.loading;
return [_c('div',{staticClass:"NoOptions"},[_vm._v("Ничего не найдено")])]}},{key:"selected-option-container",fn:function(ref){
          var option = ref.option;
          var deselect = ref.deselect;
          var multiple = ref.multiple;
          var disabled = ref.disabled;
return [_vm._v("\n          "+_vm._s(option.label + (_vm.innerValue[_vm.innerValue.length - 1] === option ? "" : ", "))+"\n        ")]}}]),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},[_vm._v(" "),_c('template',{slot:"open-indicator"},[_c('span',{staticClass:"custom-open-indicator"},[_c('svg',{staticClass:"open_indicator",attrs:{"width":"14","height":"9","viewBox":"0 0 14 9","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1L7 7L13 1","stroke-width":"2"}})])])]),_vm._v(" "),_c('template',{slot:"list-header"},[_c('div',_vm._l((_vm.innerValue),function(option){return _c('div',{key:'x' + option.id + option.label,staticClass:"GeoSelectViewSelectedOption needsfastclick needsfastclickroot"},[_c('span',{staticClass:"icomoon icomoon-type",class:'icomoon-geo-' + option.type + (option.type == 'jk' ? '-' + _vm.direction : ''),style:({ color: option.iconColor })}),_vm._v(" "),_c('span',{staticClass:"title",domProps:{"innerHTML":_vm._s(option.label)}}),_vm._v(" "),_c('span',{staticClass:"close",on:{"click":function($event){return _vm.$refs.select.deselect(option)}}},[_c('span',{staticClass:"icomoon icomoon-close"})])])}),0),_vm._v(" "),(!_vm.solidMode)?_c('div',{staticClass:"HeaderSelect"},_vm._l((_vm.types),function(item){return _c('span',{key:item.id,staticClass:"HeaderSelectItem needsfastclick needsfastclickroot",class:{
                'HeaderSelectItem--selected': item.id == _vm.selectedType,
              },on:{"click":function($event){_vm.selectedType = item.id}}},[_vm._v(_vm._s(item.label))])}),0):_vm._e()])],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }