<template>
  <div class="MultiSelectViewWrapper" :class="{ autoScaleMob1: isMobile, isOpen, isMobile }">
    <multiselect
      ref="select"
      :value="objectValue"
      @input="input"
      :options="options"
      :multiple="true"
      label="label"
      track-by="id"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="false"
      :preselect-first="false"
      :searchable="searchable"
      :taggable="false"
      class="MultiSelectView"
      :class="{ isMobile, searchable }"
      @open="isOpen = true"
      @close="isOpen = false"
    >
      <template slot="selection" slot-scope="{ values }">
        <span class="multiselect__single" v-if="values.length">{{ values.map((v) => v.label).join(", ") }}</span>
      </template>
      <template slot="tag" slot-scope="{ option }">
        <span v-text="option.label"></span>
      </template>
      <template slot="placeholder">
        <span v-text="placeholder" class="placeholder"></span>
      </template>
      <template slot="caret">
        <span class="caret"><svg-view name="caret"></svg-view></span>
      </template>
      <template slot="option" slot-scope="{ option }">
        <svg-view name="cb-checked"></svg-view>
        <svg-view name="cb-unchecked"></svg-view>
        <span class="option" v-text="option.label"></span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

export default {
  name: "MultiSelectView",
  components: { Multiselect },
  props: {
    placeholder: { type: String, default: "Выберите" },
    searchable: { type: Boolean, default: false },
    value: { type: Array, default: [] },
    options: { type: Array, default: [] },
  },
  computed: {
    objectValue() {
      return this.innerValue.map((v) => this.options.find((o) => o.id == v)).filter((o) => !!o);
    },
  },
  data: () => ({
    isOpen: false,
  }),
  mounted() {
    /*setInterval(() => {
      this.$refs.select.isOpen = true;
    }, 10);*/
  },
  methods: {
    input($event) {
      console.log("input", $event);
      if (isArray($event)) {
        this.innerValue = $event.map((v) => v.id);
      } else {
        this.innerValue = isObject($event) ? $event.id : $event;
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "../style.less";
</style>

<style scoped lang="less">
@import "../style.less";
.MultiSelectView {
  color: #3c4043;
  &,
  /deep/ .multiselect__single {
    font: 500 14px/16px Roboto;
  }
  .isMobile & /deep/ .multiselect__single {
    font: 500 (14px * @mobileScale) / (16px * @mobileScale) Roboto;
  }

  &.multiselect--active /deep/ .multiselect__placeholder {
    display: block;
  }
  /deep/ .multiselect__placeholder,
  /deep/ .multiselect__single {
    padding: 0;
    margin: 0;
    min-height: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /deep/ .multiselect__tags {
    min-height: 0;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 16px 15px;
    padding-right: 40px;
    transition: border-color 0.3s;

    .isMobile & {
      border-radius: 4px * @mobileScale;
      padding: 16px * @mobileScale 15px * @mobileScale;
      padding-right: 40px * @mobileScale;
    }
  }

  &:not(.searchable) {
    /deep/ .multiselect__tags, /deep/  .caret {
      cursor: pointer;
    }
  }

  &.multiselect--active /deep/ .multiselect__tags {
    border: 2px solid #c0ac9c;
    padding: 14px;
    border-radius: 4px;
    padding-right: 39px;
    z-index: 100;
    position: relative;

    .isMobile & {
      border-width: 2px * @mobileScale;
      padding: 14px * @mobileScale;
      border-radius: 4px * @mobileScale;
      padding-right: 39px * @mobileScale;
    }
  }

  /deep/ .placeholder {
    color: #bcbcbc;
  }
  /deep/ .multiselect__content {
    font: normal 13px/15px Roboto;
  }

  /deep/ .caret {
    border: none;
    position: absolute;
    top: 50%;
    right: 21px;
    width: auto;
    height: auto;
    margin-top: -8px;
    transition: transform 0.3s;
    z-index: 1;

    .isOpen & {
      z-index: 101;
    }

    .isMobile & {
      right: 21px * @mobileScale;
      margin-top: -8px;
    }

    svg {
      stroke: #7e7e7e;
      stroke-width: 2px;
      width: 14px;
      height: 9px;
      fill: none;

      .isMobile & {
        stroke-width: 2px;
        width: 14px * @mobileScale;
        height: 9px * @mobileScale;
      }
    }
  }
  &.multiselect--active /deep/ .caret {
    transform: rotate(180deg);
    svg {
      stroke: #b09783;
    }
  }

  /deep/ .multiselect__option {
    padding: 14px 16px;
    min-height: 0;
    outline: none;
    font: normal 13px/15px Roboto;
    .isMobile & {
      padding: 14px * @mobileScale 16px * @mobileScale;
      font: normal (13px * @mobileScale) / (15px * @mobileScale) @Roboto;
    }

    svg {
      width: 19px;
      height: 18px;
      margin-bottom: -3px;
      margin-top: -10px;
      margin-right: 10px;

      .isMobile & {
        width: 19px * @mobileScale;
        height: 18px * @mobileScale;
        margin-bottom: -3px * @mobileScale;
        margin-top: -10px * @mobileScale;
        margin-right: 10px * @mobileScale;
      }
    }
    .svg--cb-checked {
      display: none;
    }
  }
  /deep/ .multiselect__element {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(230, 230, 230, 0.2);
    }
  }
  /deep/ .multiselect__option::after {
    display: none !important;
  }
  /deep/ .multiselect__option--highlight {
    background: white;
    color: black;

    background: linear-gradient(0deg, rgba(230, 230, 230, 0.2), rgba(230, 230, 230, 0.2)), #ffffff !important;
  }
  /deep/ .multiselect__option--selected {
    background: white;

    .svg--cb-checked {
      display: inline-block;
    }
    .svg--cb-unchecked {
      display: none;
    }
  }

  /deep/ .multiselect__content-wrapper {
    border: 1px solid #f3f3f3;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 2px;
  }
}
//.MultiSelectView .multiselect--active .multiselect__placeholder;
</style>
