<template>
  <div :class="{ autoScaleMob: isMobile && useAutoScaleMob }">
    <component
      :is="!!href ? 'a' : 'button'"
      :type="!href ? href : null"
      class="Btn"
      :class="[
        {
          isDesktop,
          isMobile,
          blocked: blocked || loading,
          disabled,
          pulse: pulse && !blocked && !loading && !disabled,
        },
        btnClasses,
      ]"
      :href="href"
      @click="$emit('click', $event)"
    >
      <span v-if="loading" class="spinner"></span>
      <span v-else-if="!!title" class="text" v-html="title"></span>
      <slot />
    </component>
  </div>
</template>

<script>
export default {
  name: "Btn",
  props: {
    title: { type: String, default: "" },
    href: { type: String, default: null },
    type: { type: String, default: "button" },
    blocked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    pulse: { type: Boolean, default: true },
    useAutoScaleMob: { type: Boolean, default: true },
    btnClasses: { type: [Array, Object, String], default: () => [] },
  },
};
</script>

<style scoped lang="less">
@import "../style.less";
//@import "three-dots/less/_mixins.less";
.Btn {
  position: relative;
  overflow: hidden;

  display: block;
  width: 100%;
  box-sizing: border-box;
  background: @color-primary;
  font: 500 16px/20px @Roboto;
  color: white;
  appearance: none;
  border: none;
  outline: none;

  padding: 15px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;

  &.isDesktop:hover {
    background: @color-primary-hover;
  }
  &.blocked {
    pointer-events: none;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
.Btn span.text {
  display: block;
  height: 20px;
}
.Btn span.spinner {
  display: block;
  height: 6px;
  margin: 7px auto;
  .dot-flashing;
}

@dotSpacing: 10px;
@dotColor: rgba(255, 255, 255, 1);
@dotColorLight: rgba(255, 255, 255, 0.2);

.dot {
  background-color: @dotColorLight;
  border-radius: 3px;
  color: @dotColorLight;
  height: 6px;
  width: 6px;
}

.dot-flashing {
  .dot;
  animation: dotFlashing 0.5s 0.25s infinite linear alternate;
  position: relative;
  &::before,
  &::after {
    .dot;
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    animation: dotFlashing 0.5s infinite alternate;
    left: -@dotSpacing;
  }
  &::after {
    animation: dotFlashing 0.5s 0.5s infinite alternate;
    left: @dotSpacing;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: @dotColorLight;
  }
  100% {
    background-color: @dotColor;
  }
}

.Btn.pulse:after {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 50%;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  animation: pulse 2.5s ease-out infinite;
  opacity: 0;
  margin-left: -25px;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  60% {
    transform: scale(5);
    opacity: 0;
  }
  to {
    transform: scale(5);
    opacity: 0;
  }
}
</style>
