<template>
  <div class="CallbackSelect" :class="{ isMobile, isDesktop }">
    <div class="CallbackSelect-phone" v-if="isMobile" v-html="phoneHtml"></div>
    <div class="Content" :class="{ autoScaleMobBlock: isMobile }">
      <img class="CallbackSelect-photo" src="/static/front/img/assets_v2/CallbackUserpic.png" />
      <div class="CallbackSelect-caption">Валентина ответит на любые вопросы в чате</div>
      <btn class="Whatsapp defaulthover" :pulse="false" :href="waUrl" :useAutoScaleMob="false">
        <span><svg-view name="whatsapp" />Написать в WhatsApp</span>
      </btn>
      <btn class="Telegram defaulthover" :pulse="false" href="https://t.me/welhomerubot" :useAutoScaleMob="false">
        <span><svg-view name="telegram" />Написать в Telegram</span>
      </btn>
      <btn class="Call defaulthover" :pulse="false" :useAutoScaleMob="false" @click="call">
        <span><svg-view name="call" />Заказать звонок</span>
      </btn>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    phoneHtml: $(".call_phone_a1").html(),
  }),
  computed: {
    waUrl() {
        return this.isMobile ? 'whatsapp://send?phone=74952288208' : 'https://web.whatsapp.com/send?phone=74952288208';
    }
  },
  methods: {
    call() {
      this.$emit("call");
    },
  },
};
</script>

<style scoped lang="less">
@import "../common/style.less";

.CallbackSelect {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 67px;
}
.CallbackSelect-phone {
  position: absolute;
  font: 500 (@mobileScale * 18px) / (@mobileScale * 24px) @Roboto;
  top: @mobileScale * 18px;
  left: @mobileScale * 18px;

  & /deep/ a {
    color: black;
    text-decoration: none;
  }
}
.CallbackSelect-photo {
  width: 100px;
  height: 100px;
  align-self: center;
}
.CallbackSelect-caption {
  font: 500 18px/24px @Roboto;
  margin: 20px 0 32px;
}

.CallbackSelect /deep/ .Btn {
  border-radius: 28px;
  margin-bottom: 8px;
  //  padding: 18px;
  span {
    font-size: 18px;
    line-height: 26px;
  }
}
.Whatsapp /deep/ .Btn {
  background: #43d55b !important;
  svg {
    width: 24px;
    height: 25px;
    margin: -5px 10px -5px 0;
  }
}
.Telegram /deep/ .Btn {
  background: #33a9e5 !important;
  svg {
    width: 24px;
    height: 21px;
    margin: -5px 10px -5px 0;
  }
}
.Call /deep/ .Btn {
  background: #000 !important;
  svg {
    width: 23px;
    height: 23px;
    margin: -5px 10px -5px 0;
  }
}
.CallbackSelect.isMobile .Content {
  height: 644px;
}
</style>
