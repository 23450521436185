import Vue from "vue";
import svg4everybody from "svg4everybody";
import objectFitImages from "object-fit-images/dist/ofi.common-js.js";
import Promise from "promise-polyfill";
import axios from "axios";
import Cookies from "js-cookie";
import "babel-polyfill";
import MobileDetect from "mobile-detect";

import IndexFilter from "./IndexFilter/IndexFilter.vue";
import BurgerMenu from "./BurgerMenu/BurgerMenu.vue";
import Questionnaire from "./Questionnaire/Questionnaire.vue";
import HiddenBase from "./HiddenBase/HiddenBase.vue";
import CatalogForm from "./CatalogForm/CatalogForm.vue";
import ObjectSimpleRequestForm from "./ObjectSimpleRequestForm/ObjectSimpleRequestForm.vue";
import CookieLaw from "./CookieLaw/CookieLaw.vue";
import Callback from "./Callback/Callback.vue";
import FilterLinks from "./FilterLinks/FilterLinks.vue";

import "./common/icomoon/style.less";

import { configure, extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required, email, length } from "vee-validate/dist/rules";
extend("required", required);
extend("email", email);
extend("length", length);
extend("phone", {
  params: ["completed"],
  validate: (value, { completed }) => {
    return completed;
  },
  message: "Введите корректный номер телефона",
});

svg4everybody({ polyfill: HOT_RELOADING });
objectFitImages();

axios.interceptors.request.use(
  (config) => {
    config.headers["X-CSRFToken"] = Cookies.get("csrftoken") || $('meta[name="csrf-token"]').attr("content");
    return config;
  },
  (error) => Promise.reject(error)
);

const isAdmin = $(document.body).hasClass("authenticated_admin");
const isMobile = $(document.body).hasClass("mobile");
const md = new MobileDetect(window.navigator.userAgent);

const metro_lines = [
  ["01", "Сокольническая", "#ed1b35"],
  ["02", "Замоскворецкая", "#44b85c"],
  ["03", "Арбатско-Покровская", "#0078bf"],
  ["04", "Филёвская", "#19c1f3"],
  ["05", "Кольцевая", "#894e35"],
  ["06", "Калужско-Рижская", "#f58631"],
  ["07", "Таганско-Краснопресненская", "#8e479c"],
  ["08", "Калининский радиус", "#ffcb31"],
  ["8a", "Солнцевский радиус", "#ffcb31"],
  ["09", "Серпуховско-Тимирязевская", "#a1a2a3"],
  ["10", "Люблинско-Дмитровская", "#b3d445"],
  ["11", "Каховская", "#79cdcd"],
  ["12", "Бутовская", "#acbfe1"],
  ["13", "Монорельс", "#9999ff"],
  ["14", "Большое кольцо метро", "#30d5c8"],
  ["15", "Большое кольцо метро", "#ffc0cb"],
  ["16", "Калининско-Солнцевская линия", "#ffd702"],
  ["17", "Некрасовская линия", "#ffc0cb"],
];

Vue.mixin({
  components: { ValidationObserver, ValidationProvider },

  data: () => ({
    isAdmin,
    isMobile,
    isDesktop: !isMobile,
    isIOS: isMobile && md.is("iOS"),
    stdConnectionMethodsChoices: [
      { id: "Телефон", label: "Телефон", icon: "call"},
      { id: "Telegram", label: "Telegram", icon: "telegram"},
      { id: "WhatsApp", label: "WhatsApp", icon: "whatsapp"},
    ],
  }),
  methods: {
    //квартиру, квартиры, квартир
    plural(n, s) {
      if (n <= 0) {
        return s[2];
      }
      if (n == 1) {
        return s[0];
      }
      if (n <= 4) {
        return s[1];
      }
      if (n <= 20) {
        return s[2];
      }
      if (n % 10 == 0) {
        return s[2];
      }
      if (n % 10 == 1) {
        return s[0];
      }
      if (n % 10 <= 4) {
        return s[1];
      }
      return s[2];
    },
    setTimeout(fn, timeout) {
      setTimeout(fn, timeout);
    },

    metro_line_color(metroId) {
      var metroLine = Math.floor(parseInt(metroId / 1000, 10));
      for (var i = 0; i < metro_lines.length; i++) {
        if (parseInt(metro_lines[i][0], 10) == metroLine) return metro_lines[i][2];
      }
      return "#eee";
    },
  },
});

import "./common";

window.Apps = {};

if ($("#IndexFilterApp").length != 0) {
  window.Apps.Filters = new Vue({
    el: "#IndexFilterApp",
    name: "IndexFilterApp",
    render: (h) => h(IndexFilter),
    methods: {},
    created() {
      console.log("FiltersApp created...");
    },
  });
}

if ($("#BurgerMenuApp").length != 0) {
  window.Apps.BurgerMenu = new Vue({
    el: "#BurgerMenuApp",
    name: "BurgerMenuApp",
    render: (h) => h(BurgerMenu),
    methods: {},
    created() {
      console.log("BurgerMenuApp created...");
    },
  });
}

if ($("#QuestionnaireApp").length != 0) {
  window.Apps.QuestionnaireApp = new Vue({
    el: "#QuestionnaireApp",
    name: "QuestionnaireApp",
    render: (h) => h(Questionnaire),
    methods: {},
    created() {
      console.log("QuestionnaireApp created...");
    },
  });
}

if ($("#HiddenBaseApp").length != 0) {
  window.Apps.HiddenBaseApp = new Vue({
    el: "#HiddenBaseApp div",
    name: "HiddenBaseApp",
    render: (h) => h(HiddenBase),
    methods: {},
    created() {
      console.log("HiddenBaseApp created...");
    },
  });
}

if ($("#CatalogFormApp").length != 0) {
  window.Apps.QuestionnaireApp = new Vue({
    el: "#CatalogFormApp",
    name: "CatalogFormApp",
    render: (h) => h(CatalogForm),
    methods: {},
    created() {
      console.log("CatalogFormApp created...");
    },
  });
}

if ($("#ObjectSimpleRequestForm").length != 0) {
  window.Apps.ObjectSimpleRequestForm = new Vue({
    el: "#ObjectSimpleRequestForm",
    name: "ObjectSimpleRequestForm",
    render: (h) => h(ObjectSimpleRequestForm),
    methods: {},
    created() {
      console.log("ObjectSimpleRequestForm created...");
    },
  });
}

if ($("#CookieLaw").length != 0) {
  window.Apps.CookieLaw = new Vue({
    el: "#CookieLaw",
    name: "CookieLaw",
    render: (h) => h(CookieLaw),
    methods: {},
    created() {
      console.log("CookieLaw created...");
    },
  });
}

if ($("#CallbackApp").length != 0) {
  window.Apps.Callback = new Vue({
    el: "#CallbackApp",
    name: "CallbackApp",
    render: (h) => h(Callback),
    methods: {
      show() {
        this.$children?.[0]?.show?.();
      },
      hide() {
        this.$children?.[0]?.hide?.();
      },
    },
    created() {
      console.log("CallbackApp created...");
    },
  });
}

if ($("#FilterLinksApp").length != 0) {
  window.Apps.FilterLinks = new Vue({
    el: "#FilterLinksApp",
    name: "FilterLinksApp",
    render: (h) => h(FilterLinks),
    methods: {
      show() {
        this.$children?.[0]?.show?.();
      },
      hide() {
        this.$children?.[0]?.hide?.();
      },
    },
    created() {
      console.log("FilterLinksApp created...");
    },
  });
}
