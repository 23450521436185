<template>
  <div class="SelectViewWrapper" :class="{ autoScaleMob1: autoScaleMob && isMobile, isOpen, isMobile: isMobile && autoScaleMob }">
    <div class="SelectView u-InputMarginView" :class="[modes.map((m) => `SelectView--${m}`), { 'u-ErrorView': hasError, border }]">
      <v-select
        ref="select"
        :autoscroll="false"
        class="SelectViewInput"
        :value="selected"
        v-on:input="input"
        :options="options"
        :placeholder="placeholder"
        :max-height="maxHeight"
        :clearable="false"
        :searchable="searchable"
        :multiple="multiple"
        :clearSearchOnSelect="true"
        :closeOnSelect="true"
        @open="
          () => {
            isOpen = true;
            $emit('open');
          }
        "
        @close="
          () => {
            isOpen = false;
            $emit('close');
          }
        "
      >
        <template slot="option" slot-scope="option">
          <span class="SelectViewOption SelectViewOption--selected" :style="{ fontFamily: option.label == '₽' ? '\'ALS Rubl\'' : '' }" v-html="option.label == '₽' ? 'a' : option.label"></span>
          <svg-view name="option-selected-indicator" />
        </template>
        <template slot="selected-option" slot-scope="option">
          <span class="SelectViewOption SelectViewOption--option" :style="{ fontFamily: option.label == '₽' ? '\'ALS Rubl\'' : '' }" v-html="option.label == '₽' ? 'a' : option.label"></span>
        </template>
        <template slot="open-indicator">
          <span class="custom-open-indicator">
            <svg-view name="open-indicator" />
          </span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import isObject from "lodash/isObject";

export default {
  name: "SelectView",
  components: {
    vSelect,
  },
  props: {
    value: { type: [Object, Number, String], default: "" },
    options: { type: Array, default: () => [] },
    placeholder: { type: String, default: "" },
    modes: { type: Array, default: () => [] },
    maxHeight: { type: String, default: "400px" },
    searchable: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    border: { type: Boolean, default: true },
    debugMenu: { type: Boolean, default: false },
    autoScaleMob: { type: Boolean, default: true },
    errors: { type: Array, default: () => [] },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    selected() {
      return this.options.find((v) => v.id == this.innerValue);
    },
  },
  mounted() {
    if (this.debugMenu) {
      setInterval(() => {
        if (this.$refs.select) {
          this.$refs.select.open = true;
        }
      }, 10);
    }
    this.value && this.value.$touch && this.value.$touch();
  },
  methods: {
    input($event) {
      this.innerValue = isObject($event) ? $event.id : $event;
    },
  },
};
</script>

<style scoped lang="less">
@import "vue-select/dist/vue-select.css";
@import "../style.less";

.SelectView {
  position: relative;
}
.SelectView:not(.border) .SelectViewInput /deep/ .vs__dropdown-toggle {
  border-color: transparent !important;
  border-radius: 0 !important;
  background: transparent;
}
.SelectViewInput {
  /deep/ .vs__dropdown-toggle {
    border: 1px solid @color-border-gray;
    border-radius: 4px;
    padding: 1px;
    font: 500 14px/16px @Roboto;
    transition: border-color 0.3s;
    z-index: 10;
    background: white;
    position: relative;

    .SelectViewWrapper.isMobile & {
      border-width: 1px * @mobileScale;
      padding: 1px * @mobileScale;
      border-radius: 4px * @mobileScale;
      font: 500 (14px * @mobileScale)/ (16px * @mobileScale) @Roboto;
    }

    .u-ErrorView & {
      border-color: red;
    }
  }

  &.vs--open /deep/ .vs__dropdown-toggle {
    padding: 0;
    border: 2px solid #c0ac9c;
    .SelectViewWrapper.isMobile & {
      padding: 0;
      border-width: 2px * @mobileScale;
    }
  }
  /deep/ .vs__dropdown-menu {
    overflow: auto !important;
    padding: 0;
    background: white;

    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 4px;
    border: none;
    z-index: 100;
  }

  /deep/ .vs__dropdown-menu li[role="option"] {
    padding: 0;
    background: none;
    color: #3c4043;
    font: 13px/15px @Roboto;
    padding: 14px 16px;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(230, 230, 230, 0.2);
    }
    .SelectViewWrapper.isMobile & {
      padding: 14px * @mobileScale 16px * @mobileScale;
      font: (13px * @mobileScale)/ (15px * @mobileScale) @Roboto;
    }

    &.vs__dropdown-option--highlight {
      background: linear-gradient(0deg, rgba(230, 230, 230, 0.2), rgba(230, 230, 230, 0.2)), #ffffff;
    }
    &.vs__dropdown-option--selected {
      color: #b09783;
    }
  }
  .svg--open-indicator {
    transition: transform 0.3s;
    stroke: #7e7e7e;
  }
  &.vs--open /deep/ .svg--open-indicator {
    transform: rotate(180deg);
    stroke: @color-primary;
  }

  /deep/ .vs__no-options {
    font: normal 12px/30px @Roboto;
    letter-spacing: 0;
    color: @color-light-text;
    .SelectViewWrapper.isMobile & {
      font: (12px * @mobileScale)/ (30px * @mobileScale) @Roboto;
    }
  }
  /deep/ .vs__selected-tag {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }
  /deep/ .vs__selected-options {
    padding: 15px;
    .SelectViewWrapper.isMobile & {
      padding: 15px * @mobileScale;
    }
  }
  /deep/ .vs__selected {
    color: #3c4043;
    padding: 0;
    margin: 0;
    border: none;
    position: relative;
    opacity: 1;
    font: 500 14px/16px @Roboto;
    .SelectViewWrapper.isMobile & {
      font: 500 (14px * @mobileScale)/ (16px * @mobileScale) @Roboto;
    }
  }
  /deep/ .vs__search {
    color: #3c4043;
    padding: 0;
    margin: 0;
    border: none;
    position: relative;
    opacity: 1;
    font: 500 14px/16px @Roboto;
    .SelectViewWrapper.isMobile & {
      font: 500 (14px * @mobileScale)/ (16px * @mobileScale) @Roboto;
    }
  }

  /deep/ .vs__dropdown-option svg {
    svg {
      float: right;
    }
  }
  /deep/ .vs__dropdown-option:not(.vs__dropdown-option--selected) {
    svg {
      display: none;
    }
  }
  /deep/ .vs__actions {
    padding: 0;
  }
}

.custom-open-indicator {
  padding: 0 19px;
  .SelectViewWrapper.isMobile & {
    padding: 0 19px * @mobileScale;
  }
}
.svg--option-selected-indicator {
  float: right;
  width: 14px;
  height: 10px;
  .SelectViewWrapper.isMobile & {
    width: 14px * @mobileScale;
    height: 10px * @mobileScale;
  }
}
.svg--open-indicator {
  stroke: black;
  stroke-width: 2px;
  fill: none;
  width: 14px;
  height: 9px;
  .SelectViewWrapper.isMobile & {
    width: 14px * @mobileScale;
    height: 9px * @mobileScale;
  }
}

.SelectViewOption {
  pointer-events: none;
}
.u-ShowErrors .SelectView.u-ErrorView .SelectViewInput /deep/ .dropdown-toggle {
  border-color: red !important;
}
</style>
