<template>
  <cookie-law
    ref="law"
    class="CookieLaw"
    theme="dark-lime"
    :class="{ isMobile, isDesktop }"
  >
    <div class="CookieLaw-inner">
      <span>
        Находясь на сайте welhome.ru вы даете согласие на использование
        <a
          class="defaulthover"
          href="https://www.welhome.ru/disclaimer"
          target="_blank"
        >
          cookies
        </a>
        и соглашаетесь с
        <a
          class="defaulthover"
          href="https://www.welhome.ru/personal-data-processing"
          target="_blank"
        >
          условиями обработки персональных данных
        </a>
      </span>
      <div class="CookieLaw-buttonWrapper">
        <btn title="Закрыть" @click="accept" />
      </div>
    </div>
  </cookie-law>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  components: { CookieLaw },
  methods: {
    accept() {
      this.$refs.law.accept();
    },
  },
};
</script>

<style scoped lang="less">
@import "../common/style.less";
.CookieLaw {
  z-index: 9999999999;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  backdrop-filter: blur(4px);
  box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.23);
  color: @color-black;
  //font: 500 16px/19px @Roboto;
  font-size: 16px;
  width: auto;
  a {
    color: @color-primary;
  }
  .Btn {
    width: auto;
  }
  span {
    margin-right: 50px;
  }

  &.isDesktop {
    left: 120px;
    bottom: 50px;
    right: 480px;
  }
  &.isDesktop .CookieLaw-inner {
    align-items: center;
    flex-direction: row;
    display: flex;
    align-self: auto;
    height: auto;
    margin: 0;
    width: 100%;
    justify-content: space-between;
  }
}
.CookieLaw.isMobile {
  .CookieLaw-buttonWrapper {
    width: 200px;
    float: right;
    margin-top: 30px;
  }
  span {
    margin-right: 30px;
    font-size: 30px;
  }
}
</style>
