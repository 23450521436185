<template>
  <form class="ObjectSimpleRequestForm" ref="root" :class="{ isMobile, isDesktop, visible, thanks }" id="object-simple-request-form">
    <validation-observer v-slot="{ handleSubmit, valid }">
      <div class="content" v-if="!thanks">
        <div class="node clear-block node-webform">
          <div class="header row">или оставьте заявку:</div>

          <div class="row">
            <validation-provider rules="required" v-slot="{ errors }">
              <input-view v-model="result.name" placeholder="Имя" :errors="errors" border_radius="0" padding="12px 15px" fontHeight="12px/14px" />
            </validation-provider>
          </div>
          <div class="row">
            <validation-provider :rules="{ required: true, phone: { completed: result.phoneCompleted } }" v-slot="{ errors }">
              <masked-input-view v-model="result.phone" placeholder="Телефон" mask="\+\7 (111) 111-11-11" @completed="result.phoneCompleted = $event" :errors="errors" border_radius="0" padding="12px 15px" fontHeight="12px/14px" />
            </validation-provider>
          </div>

          <div class="row">
            <validation-provider rules="required" v-slot="{ errors }">
              <div class="connectionMethodBox" :class="{ 'u-ErrorView': hasError }">
                <div class="connectionMethodBoxTitle"><span>Способ&nbsp;связи:</span></div>
                <div class="connectionMethodBoxBody">
                  <template v-for="connectionMethod in stdConnectionMethodsChoices">
                    <div>
                      <input
                          class="connectionMethod"
                          type="radio"
                          v-bind:value="connectionMethod.id"
                          :id="`connectionMethod-${connectionMethod.id}`"
                          v-model="result.connectionMethod"
                      >
                      <label
                          class="connectionMethodLabel"
                          :class="{'active': connectionMethod.id === result.connectionMethod}"
                          :for="`connectionMethod-${connectionMethod.id}`"
                      >
                        <span
                          class="connectionMethodIcon"
                          :class="{'active': connectionMethod.id === result.connectionMethod}"
                        >
                          <svg-view :name="connectionMethod.icon" />
                        </span>
                        {{connectionMethod.label}}
                      </label>
                    </div>
                  </template>
                </div>
              </div>
            </validation-provider>
          </div>

          <div class="row">
            <btn type="button" class="needsclick" :loading="submitting" :pulse="valid" title="Отправить" @click.prevent="handleSubmit(submit)" btnClasses="smallButton" />
          </div>

          <div class="row">
            <validation-provider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
              <div class="form-agreement" :class="{ 'u-ErrorView': errors.length > 0 }">
                <div class="check">
                  <input type="checkbox" v-model="result.comfirmSend" id="confirmSend"/>
                </div>
                <div>
                  <label class="gray-description" for="confirmSend">Нажимая кнопку "Отправить", вы подтверждаете согласие на обработку персональных данных в соответствии с
                    <a class="underline" target="_blank" href="https://www.welhome.ru/disclaimer">"Положением об обработке персональных данных ООО "ВЭЛХОМ"</a>
                  </label>
                </div>
              </div>
            </validation-provider>
          </div>
        </div>
      </div>
    </validation-observer>

    <div class="thanks" v-if="thanks">
      <div class="header">Спасибо!</div>
      <div class="description">Ваша заявка отправлена.<br />Мы свяжемся с вами в ближайшее время.</div>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    visible: false,
    submitting: false,
    thanks: false,

    result: {
      name: "",
      phoneCompleted: false,
      phone: "",
      comfirmSend: true,
      connectionMethod: "Телефон",
    },
  }),

  mounted() {},

  methods: {
    submit(e) {
      e?.preventDefault?.();

      this.submitting = true;
      const formData = new FormData();

      let credentials = Comagic.getCredentials();

      let data = {
        form_cmd: "form_quick",
        name: this.result.name,
        phone: this.result.phone,
        //mail: 'fromsite@welhome.ru',
        department: 1,
        project: $("#ObjectSimpleRequestForm-project").val(),
        project_link: $("#ObjectSimpleRequestForm-project_link").val(),
        message: "клиент с сайта",
        method: this.result.connectionMethod,
        agreement: "on",
        ...credentials,
      };
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      axios
        .post("/api/submit_form/7834/", formData, {
          headers: {
            "Content-Type": "form-data",
          },
        })
        .then(() => {
          var dataLayer = (window.dataLayer = window.dataLayer || []);
          var evt = {
            event: "otpravka-formi-" + this.$refs.root.id,
            eventCategory: "lead",
          };
          dataLayer.push(evt);
          console.log({ evt });

          this.thanks = true;
          $(".ObjectSimpleRequestForm-static").hide();
        })
        .catch(() => {
          alert("Не удалось отправить заявку, попробуйте позже!");
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
@import "../common/style.less";

.row {
  margin-bottom: 16px;
  padding: 0;
}

.content {
  padding-bottom: 30px;
}

.thanks {
  padding: 50px 0;

  .header {
    margin-bottom: 10px;
  }
}

.form-agreement {
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  padding: 0;
  .rfont(400, 12, 12, MyslC);

  .check {
    padding-right: 10px;
  }

  input {
    margin: 0;
  }
}

.connectionMethod {
  display: none;
}

.connectionMethodLabel {
  .rfont(500, 12, 14, Roboto);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  padding-left: 18px;
  height: 20px;
  gap: 6px;
  color: #BDBDBE;
  cursor: pointer;
}

.connectionMethodIcon {
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: #BDBDBE;
  border-radius: 10px;
}

.active {
  color: #777A7F;
  .connectionMethodIcon {
    background-color: #B09783;
  }
  svg {
      background-color: #B09783;
    }
}

.connectionMethodBox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  box-shadow: none;
  border: 1px solid #e6e6e6;
  margin: 0;
  text-align: left;
}

.connectionMethodBoxTitle {
  .rfont(600, 12, 14, Roboto);
  color: #777a7f;
  margin-left: 20px;
}

.connectionMethodBoxBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 18px;
  margin-right: 20px;
  svg {
    padding: 3px;
    width: 14px;
    height: 14px;
    border-radius: 10px;
  }
}
</style>


<style lang="less">
@import "../common/style.less";

.ObjectSimpleRequestForm-static {
  padding: 50px 70px 0;
  max-width: 800px;
}

.ObjectSimpleRequestForm-wrapper {
  border-top: 1px solid #e3ddd1;
  text-align: center;

  .header {
    margin-top: 10px;
    .rfont(400, 19, normal, MyslC);
  }

  .description {
    .rfont(400, 14, normal, MyslC);
  }

  .phone {
    margin-top: 10px;
    .rfont(700, 19, normal, MyslC);

    * {
      color: black !important;
    }
  }
}

.smallButton {
  font: 500 14px/16px @Roboto !important;
  padding: 12px 15px !important;
}
</style>
