<template>
  <div class="Filters needsclickroot" :class="{ isMobile, isDesktop }">
    <filter-direction-switcher-desktop v-if="isDesktop" v-model="filters.direction" />
    <select-view v-else class="MobileTypeSelect" placeholder="Выберите тип" v-model="filters.direction" key="filters.direction" :options="options.direction" />

    <div class="FiltersContent">
      <div v-if="filters.direction == 1">
        <div class="Row">
          <div class="RowItem" :style="{ width: sizes.op }">
            <select-view placeholder="Выберите операцию" v-model="filters[1].op" key="filters[1].op" :options="options.op" @input="changeOp(1)" />
          </div>
          <div class="RowItem" :style="{ width: sizes.type }">
            <multi-select-view placeholder="Тип недвижимости" v-model="filters[1].types" key="filters[1].types" :options="options.types[1]" />
          </div>
          <div class="RowItem" :style="{ width: sizes.geo }">
            <geo-select-view
              :direction="filters.direction"
              placeholder="Адрес, район или название ЖК"
              v-model="filters[1].geo"
              key="filters[1].geo"
              :debugMenu="false"
              :opts="options.geo[1]"
              :types="options.geo[1].types"
            />
          </div>
        </div>

        <div class="Row">
          <div class="RowItem" :style="{ width: sizes.price }" :class="{ priceOpened }">
            <range-slider v-model="filters[1].price" :key="'filters[1].price' + cver" :min="filters[1].priceRange[0]" :max="filters[1].priceRange[1]" label="Цена" :customComponentWidth="60">
              <select-view
                slot="customcomponent"
                :autoScaleMob="false"
                v-model="filters.cur"
                key="filters.cur"
                class="PriceCurrency"
                placeholder
                :debugMenu="false"
                :border="false"
                :options="options.cur"
                @open="priceOpened = true"
                @close="setTimeout(() => (priceOpened = false), 300)"
              />
            </range-slider>
          </div>
          <div class="RowItem" :style="{ width: sizes.area }">
            <range-slider v-model="filters[1].area" :key="'filters[1].area' + cver" :min="filters[1].areaRange[0]" :max="filters[1].areaRange[1]" label="Площадь" unit="м²"> </range-slider>
          </div>
          <div class="RowItem" :style="{ width: sizes.id }">
            <input-view v-model="filters.id" key="filters.id" placeholder="ID объекта" />
          </div>
        </div>

        <div class="Row">
          <div class="RowItem">
            <check-view v-model="filters[1].nova" caption="От застройщика" />
          </div>
          <div class="RowItem"></div>
          <div class="RowItem RowItem--Results">
            <span class="ResultsCount">{{resultsCount != null ? pluralOffers : '&nbsp;'}}</span>
            <btn :loading="isLoading" :style="{width: sizes.btn}" :title="resultsCount > 0 ? 'Показать' : 'Ничего не найдено'" :blocked="!pageUrl || !resultsCount" :href="resultsUrl || pageUrl" />
          </div>
        </div>
      </div>

      <div v-if="filters.direction == 2">
        <div class="Row">
          <div class="RowItem" :style="{ width: sizes.op }">
            <select-view placeholder="Выберите операцию" v-model="filters[2].op" key="filters[2].op" :options="options.op" @input="changeOp(2)" />
          </div>
          <div class="RowItem" :style="{ width: sizes.type }">
            <multi-select-view placeholder="Тип недвижимости" v-model="filters[2].types" key="filters[2].types" :options="options.types[2]" />
          </div>
          <div class="RowItem" :style="{ width: sizes.geo }">
            <geo-select-view
              :direction="filters.direction"
              placeholder="Шоссе, поселок"
              v-model="filters[2].geo"
              key="filters[2].geo"
              :debugMenu="false"
              :opts="options.geo[2]"
              :types="options.geo[2].types"
            />
          </div>
        </div>

        <div class="Row">
          <div class="RowItem" :style="{ width: sizes.price }" :class="{ priceOpened }">
            <range-slider v-model="filters[2].price" :key="'filters[2].price' + cver" :min="filters[2].priceRange[0]" :max="filters[2].priceRange[1]" label="Цена" :customComponentWidth="60">
              <select-view
                slot="customcomponent"
                :autoScaleMob="false"
                v-model="filters.cur"
                key="filters.cur"
                class="PriceCurrency"
                placeholder
                :debugMenu="false"
                :border="false"
                :options="options.cur"
                @open="priceOpened = true"
                @close="setTimeout(() => (priceOpened = false), 300)"
              />
            </range-slider>
          </div>
          <div class="RowItem" :style="{ width: sizes.area }">
            <range-slider v-model="filters[2].area" :key="'filters[2].area' + cver" :min="filters[2].areaRange[0]" :max="filters[2].areaRange[1]" label="Площадь" unit="м²"> </range-slider>
          </div>
          <div class="RowItem" :style="{ width: sizes.id }">
            <input-view v-model="filters.id" key="filters.id" placeholder="ID объекта" />
          </div>
        </div>

        <div class="Row">
          <div class="RowItem" :style="{ width: sizes.areaSize }">
            <range-slider v-model="filters[2].areaSize" :key="'filters[2].areaSize' + cver" :min="filters[2].areaSizeRange[0]" :max="filters[2].areaSizeRange[1]" label="Площадь участка" unit="соток">
            </range-slider>
          </div>
          <div class="RowItem"></div>
          <div class="RowItem RowItem--Results">
            <span class="ResultsCount">{{resultsCount != null ? pluralOffers : '&nbsp;'}}</span>
            <btn :loading="isLoading" :style="{width: sizes.btn}" :title="resultsCount > 0 ? 'Показать' : 'Ничего не найдено'" :blocked="!pageUrl || !resultsCount" :href="resultsUrl || pageUrl" />
          </div>
        </div>
      </div>

      <div v-if="filters.direction == 3">
        <div class="Row">
          <div class="RowItem" :style="{ width: sizes.op }">
            <select-view placeholder="Выберите операцию" v-model="filters[3].op" key="filters[3].op" :options="options.op" @input="changeOp(3)" />
          </div>
          <div class="RowItem" :style="{ width: sizes.type }">
            <multi-select-view placeholder="Тип недвижимости" v-model="filters[3].types" key="filters[3].types" :options="options.types[3]" />
          </div>
          <div class="RowItem" :style="{ width: sizes.geo }">
            <geo-select-view
              :direction="filters.direction"
              placeholder="Страна"
              v-model="filters[3].geo"
              key="filters[3].geo"
              :debugMenu="false"
              :opts="options.geo[3]"
              :types="options.geo[3].types"
            />
          </div>
        </div>

        <div class="Row">
          <div class="RowItem" :style="{ width: sizes.price }" :class="{ priceOpened }">
            <range-slider v-model="filters[3].price" :key="'filters[3].price' + cver" :min="filters[3].priceRange[0]" :max="filters[3].priceRange[1]" label="Цена" :customComponentWidth="60">
              <select-view
                slot="customcomponent"
                :autoScaleMob="false"
                v-model="filters.cur"
                key="filters.cur"
                class="PriceCurrency"
                placeholder
                :debugMenu="false"
                :border="false"
                :options="options.cur"
                @open="priceOpened = true"
                @close="setTimeout(() => (priceOpened = false), 300)"
              />
            </range-slider>
          </div>
          <div class="RowItem" :style="{ width: sizes.area }">
            <range-slider v-model="filters[3].area" :key="'filters[2].area' + cver" :min="filters[3].areaRange[0]" :max="filters[3].areaRange[1]" label="Площадь" unit="м²"> </range-slider>
          </div>
          <div class="RowItem" :style="{ width: sizes.id }">
            <input-view v-model="filters.id" key="filters.id" placeholder="ID объекта" />
          </div>
        </div>

        <div class="Row">
          <div class="RowItem"></div>
          <div class="RowItem RowItem--Results">
            <span class="ResultsCount">{{resultsCount != null ? pluralOffers : '&nbsp;'}}</span>
            <btn :loading="isLoading" :style="{width: sizes.btn}" :title="resultsCount > 0 ? 'Показать' : 'Ничего не найдено'" :blocked="!pageUrl || !resultsCount" :href="resultsUrl || pageUrl" />
          </div>
        </div>
      </div>

      <div v-if="filters.direction == 4">
        <div class="Row">
          <div class="RowItem" :style="{ width: sizes.op }">
            <select-view placeholder="Выберите операцию" v-model="filters[4].op" key="filters[4].op" :options="options.op" @input="changeOp(4)" />
          </div>
          <div class="RowItem" :style="{ width: sizes.type }">
            <multi-select-view placeholder="Тип недвижимости" v-model="filters[4].types" key="filters[4].types" :options="options.types[4]" />
          </div>
          <div class="RowItem" :style="{ width: sizes.geo }">
            <geo-select-view
              :direction="filters.direction"
              placeholder="Адрес, район или название БЦ"
              v-model="filters[4].geo"
              key="filters[4].geo"
              :debugMenu="false"
              :opts="options.geo[4]"
              :types="options.geo[4].types"
            />
          </div>
        </div>

        <div class="Row">
          <div class="RowItem" :style="{ width: sizes.price }" :class="{ priceOpened }">
            <range-slider v-model="filters[4].price" :key="'filters[3].price' + cver" :min="filters[4].priceRange[0]" :max="filters[4].priceRange[1]" label="Цена" :customComponentWidth="60">
              <select-view
                slot="customcomponent"
                :autoScaleMob="false"
                v-model="filters.cur"
                key="filters.cur"
                class="PriceCurrency"
                placeholder
                :debugMenu="false"
                :border="false"
                :options="options.cur"
                @open="priceOpened = true"
                @close="setTimeout(() => (priceOpened = false), 300)"
              />
            </range-slider>
          </div>
          <div class="RowItem" :style="{ width: sizes.area }">
            <range-slider v-model="filters[4].area" :key="'filters[4].area' + cver" :min="filters[4].areaRange[0]" :max="filters[4].areaRange[1]" label="Площадь" unit="м²"> </range-slider>
          </div>
          <div class="RowItem" :style="{ width: sizes.id }">
            <input-view v-model="filters.id" key="filters.id" placeholder="ID объекта" />
          </div>
        </div>

        <div class="Row">
          <div class="RowItem"></div>
          <div class="RowItem RowItem--Results">
            <span class="ResultsCount">{{resultsCount != null ? pluralOffers : '&nbsp;'}}</span>
            <btn :loading="isLoading" :style="{width: sizes.btn}" :title="resultsCount > 0 ? 'Показать' : 'Ничего не найдено'" :blocked="!pageUrl || !resultsCount" :href="resultsUrl || pageUrl" />
          </div>
        </div>
      </div>

      <div v-if="filters.direction == 5">
        <div class="Row">
          <div class="RowItem" :style="{ width: sizes.op }">
            <select-view placeholder="Выберите операцию" v-model="filters[5].op" key="filters[5].op" :options="options.op" @input="changeOp(5)" />
          </div>
          <div class="RowItem" :style="{ width: sizes.type }">
            <multi-select-view placeholder="Тип недвижимости" v-model="filters[5].types" key="filters[5].types" :options="options.types[5]" />
          </div>
          <div class="RowItem" :style="{ width: sizes.geo }">
            <geo-select-view
              :direction="filters.direction"
              placeholder="Адрес, район или название ТЦ"
              v-model="filters[5].geo"
              key="filters[5].geo"
              :debugMenu="false"
              :opts="options.geo[5]"
              :types="options.geo[5].types"
            />
          </div>
        </div>

        <div class="Row">
          <div class="RowItem" :style="{ width: sizes.price }" :class="{ priceOpened }">
            <range-slider v-model="filters[5].price" :key="'filters[5].price' + cver" :min="filters[5].priceRange[0]" :max="filters[5].priceRange[1]" label="Цена" :customComponentWidth="60">
              <select-view
                slot="customcomponent"
                :autoScaleMob="false"
                v-model="filters.cur"
                key="filters.cur"
                class="PriceCurrency"
                placeholder
                :debugMenu="false"
                :border="false"
                :options="options.cur"
                @open="priceOpened = true"
                @close="setTimeout(() => (priceOpened = false), 300)"
              />
            </range-slider>
          </div>
          <div class="RowItem" :style="{ width: sizes.area }">
            <range-slider v-model="filters[5].area" :key="'filters[5].area' + cver" :min="filters[5].areaRange[0]" :max="filters[5].areaRange[1]" label="Площадь" unit="м²"> </range-slider>
          </div>
          <div class="RowItem" :style="{ width: sizes.id }">
            <input-view v-model="filters.id" key="filters.id" placeholder="ID объекта" />
          </div>
        </div>

        <div class="Row">
          <div class="RowItem"></div>
          <div class="RowItem RowItem--Results">
            <span class="ResultsCount">{{resultsCount != null ? pluralOffers : '&nbsp;'}}</span>
            <btn :loading="isLoading" :style="{width: sizes.btn}" :title="resultsCount > 0 ? 'Показать' : 'Ничего не найдено'" :blocked="!pageUrl || !resultsCount" :href="resultsUrl || pageUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterDirectionSwitcherDesktop from "./components/FilterDirectionSwitcherDesktop.vue";
import GeoSelectView from "./components/GeoSelectView.vue";
import axios from "axios";
import debounce from "lodash/debounce";

export default {
  components: { FilterDirectionSwitcherDesktop, GeoSelectView },
  computed: {
    curCoef() {
      if (this.filters.cur == "USD") return 1;
      if (this.filters.cur == "RUR") return 1 * usd_rate;
      if (this.filters.cur == "EUR") return (1 * usd_rate) / eur_rate;
      return 1;
    },
    debounceUpdateCount() {
      return debounce(this.updateCount, 500);
    },
    pageUrl() {
      return this.generatePageUrl();
    },
    pluralOffers() {
      if (this.resultsCount > 0) {
        return `Подобрано ${this.resultsCount} ${this.plural(this.resultsCount, ["объект", "объекта", "объектов"])}`;
      } else {
        return ''
      }
    },

    sizes() {
      return this.isMobile
        ? {}
        : {
            op: "192px",
            type: "272px",
            geo: "352px",
            price: "336px",
            area: "281px",
            id: "199px",
            nova: "336px",
            areaSize: "360px",
            btn: "240px",
          };
    },
  },
  watch: {
    curCoef(newVal, oldVal) {
      this.updateCur(1, newVal, oldVal);
      this.updateCur(2, newVal, oldVal);
      this.updateCur(3, newVal, oldVal);
      this.updateCur(4, newVal, oldVal);
      this.updateCur(5, newVal, oldVal);
    },
    pageUrl() {
      this.debounceUpdateCount();
    },
  },

  data: () => ({
    isLoading: true,

    resultsCount: null,
    resultsUrl: null,

    filtersData: null,
    cver: 1,
    priceOpened: false,

    filters: {
      direction: 1,
      1: {
        op: "sale",
        types: [101, 102, 104],
        geo: [],
        price: [null, null],
        priceRange: [null, null],
        area: [null, null],
        areaRange: [null, null],
        nova: false,
      },
      2: {
        op: "sale",
        types: [201, 202, 203, 204],
        geo: [],
        price: [null, null],
        priceRange: [null, null],
        area: [null, null],
        areaRange: [null, null],
        areaSize: [null, null],
        areaSizeRange: [null, null],
      },
    3: {
        op: "sale",
        types: [501, 502, 504],
        geo: [],
        price: [null, null],
        priceRange: [null, null],
        area: [null, null],
        areaRange: [null, null],
      },
      4: {
        op: "sale",
        types: [302, 303],
        geo: [],
        price: [null, null],
        priceRange: [null, null],
        area: [null, null],
        areaRange: [null, null],
      },
      5: {
        op: "sale",
        types: [401, 402, 403],
        geo: [],
        price: [null, null],
        priceRange: [null, null],
        area: [null, null],
        areaRange: [null, null],
      },

      cur: "RUR",
      id: "",
    },

    options: {
      direction: [
        { id: 1, label: "Городская недвижимость" },
        { id: 2, label: "Загородная недвижимость" },
        { id: 3, label: "Зарубежная недвижимость" },
        { id: 4, label: "Офисная недвижимость" },
        { id: 5, label: "Торговая недвижимость" },
      ],
      cur: [
        { id: "USD", label: "$" },
        { id: "RUR", label: "₽" },
        { id: "EUR", label: "€" },
      ],
      op: [
        { id: "sale", label: "Купить" },
        { id: "rent", label: "Арендовать" },
      ],

      types: {
        1: [
          { id: "101", label: "Квартира" },
          { id: "102", label: "Пентхаус" },
          { id: "104", label: "Жилой особняк" },
        ],
        2: [
          { id: "201", label: "Загородный дом" },
          { id: "202", label: "Участок" },
          { id: "203", label: "Таунхаус" },
          { id: "204", label: "Загородная квартира" },
        ],
        3: [
          { id: "501", label: "Дом за рубежом" },
          { id: "502", label: "Апартаменты" },
          { id: "504", label: "Коммерческий объект" },
        ],
        4: [
          { id: "302", label: "Офис в бизнес-центре" },
          { id: "303", label: "Офисный особняк" },
        ],
        5: [
          { id: "401", label: "Помещение в торговом центре" },
          { id: "402", label: "Уличное торговое помещение" },
          { id: "403", label: "Арендный бизнес" },
        ],
      },

      geo: {
        1: {
          types: [
            { label: "Округ", id: "okrug" },
            { label: "Район", id: "area" },
            { label: "Улица", id: "street" },
            { label: "Метро", id: "metro" },
            { label: "ЖК", id: "jk" },
          ],
          area: [],
          street: [],
          metro: [],
          jk: [],
          okrug: [],
        },
        2: {
          types: [
            { label: "Шоссе", id: "highway" },
            { label: "Поселок", id: "jk" },
          ],
          highway: [],
          jk: [],
        },
        3: {
          types: [
            { label: "Страна", id: "country" },
          ],
          country: [],
        },
        4: {
          types: [
            { label: "Округ", id: "okrug" },
            { label: "Район", id: "area" },
            { label: "Улица", id: "street" },
            { label: "Метро", id: "metro" },
            { label: "Бизнес-центр", id: "jk" },
          ],
          area: [],
          street: [],
          metro: [],
          jk: [],
          okrug: [],
        },
        5: {
          types: [
            { label: "Округ", id: "okrug" },
            { label: "Район", id: "area" },
            { label: "Улица", id: "street" },
            { label: "Метро", id: "metro" },
            { label: "Торговый центр", id: "jk" },
          ],
          area: [],
          street: [],
          metro: [],
          jk: [],
          okrug: [],
        },
      },
    },
  }),

  mounted() {
    // /index_search/index_search_data/
    axios.get("/api_v2/index_search/index_search_data/").then((response) => {
      let data = response.data;
      this.filtersData = data;

      this.options.geo[1].street = this.filtersData[1].street.map((x) => ({
        id: x,
        label: x,
        type: "street",
      }));
      this.options.geo[1].area = this.filtersData[1].area.map((x) => ({
        id: x[0],
        label: x[1],
        type: "area",
      }));
      this.options.geo[1].metro = this.filtersData[1].metro.map((x) => ({
        id: x[0],
        label: x[1],
        type: "metro",
        iconColor: this.metro_line_color(x[0]),
      }));
      this.options.geo[1].jk = this.filtersData[1].jk.map((x) => ({
        id: x[0],
        label: x[1],
        type: "jk",
      }));
      this.options.geo[1].okrug = this.filtersData[1].okrug.map((x) => ({
        id: x[0],
        label: x[1],
        type: "okrug",
      }));

      this.options.geo[2].highway = this.filtersData[2].highway.map((x) => ({
        id: x[0],
        label: x[1],
        type: "highway",
      }));
      this.options.geo[2].jk = this.filtersData[2].jk.map((x) => ({
        id: x[0],
        label: x[1],
        type: "jk",
      }));

      this.options.geo[3].country = this.filtersData[3].country.map((x) => ({
        id: x[0],
        label: x[1],
        type: "country",
      }));

      this.options.geo[4].street = this.filtersData[4].street.map((x) => ({
        id: x,
        label: x,
        type: "street",
      }));
      this.options.geo[4].area = this.filtersData[4].area.map((x) => ({
        id: x[0],
        label: x[1],
        type: "area",
      }));
      this.options.geo[4].metro = this.filtersData[4].metro.map((x) => ({
        id: x[0],
        label: x[1],
        type: "metro",
        iconColor: this.metro_line_color(x[0]),
      }));
      this.options.geo[4].jk = this.filtersData[4].jk.map((x) => ({
        id: x[0],
        label: x[1],
        type: "jk",
      }));
      this.options.geo[4].okrug = this.filtersData[4].okrug.map((x) => ({
        id: x[0],
        label: x[1],
        type: "okrug",
      }));

      this.options.geo[5].street = this.filtersData[5].street.map((x) => ({
        id: x,
        label: x,
        type: "street",
      }));
      this.options.geo[5].area = this.filtersData[5].area.map((x) => ({
        id: x[0],
        label: x[1],
        type: "area",
      }));
      this.options.geo[5].metro = this.filtersData[5].metro.map((x) => ({
        id: x[0],
        label: x[1],
        type: "metro",
        iconColor: this.metro_line_color(x[0]),
      }));
      this.options.geo[5].jk = this.filtersData[5].jk.map((x) => ({
        id: x[0],
        label: x[1],
        type: "jk",
      }));
      this.options.geo[5].okrug = this.filtersData[5].okrug.map((x) => ({
        id: x[0],
        label: x[1],
        type: "okrug",
      }));

      this.changeOp(1);
      this.changeOp(2);
      this.changeOp(3);
      this.changeOp(4);
      this.changeOp(5);

      this.cver++;
    });
  },

  methods: {
    updateCur(num, newCoef, oldCoef) {
      let k = newCoef / oldCoef;
      let newVals = {
        price: this.filters[num].price.map((x) => x * k),
        priceRange: this.filters[num].priceRange.map((x) => x * k),
      };
      this.filters[num] = { ...this.filters[num], ...newVals };
    },
    changeOp(num) {
      this.$nextTick(() => {
        let newFilters = {};
        newFilters.priceRange = [Math.floor(this.filtersData[num].ranges[this.filters[num].op].min_total_price) - 1, Math.ceil(this.filtersData[num].ranges[this.filters[num].op].max_total_price) + 1];
        newFilters.price = [Math.floor(this.filtersData[num].ranges[this.filters[num].op].min_total_price), Math.ceil(this.filtersData[num].ranges[this.filters[num].op].max_total_price)];

        newFilters.areaRange = [
          Math.floor(this.filtersData[num].ranges[this.filters[num].op].min_field_flat_area_value) - 1,
          Math.ceil(this.filtersData[num].ranges[this.filters[num].op].max_field_flat_area_value) + 1,
        ];
        newFilters.area = [
          Math.floor(this.filtersData[num].ranges[this.filters[num].op].min_field_flat_area_value),
          Math.ceil(this.filtersData[num].ranges[this.filters[num].op].max_field_flat_area_value),
        ];

        if (num == 2) {
          newFilters.areaSizeRange = [
            Math.floor(this.filtersData[num].ranges[this.filters[num].op].min_field_areasize_value) - 1,
            Math.ceil(this.filtersData[num].ranges[this.filters[num].op].max_field_areasize_value) + 1,
          ];
          newFilters.areaSize = [
            Math.floor(this.filtersData[num].ranges[this.filters[num].op].min_field_areasize_value),
            Math.ceil(this.filtersData[num].ranges[this.filters[num].op].max_field_areasize_value),
          ];
        }

        this.filters[num] = { ...this.filters[num], ...newFilters };
        this.updateCur(num, this.curCoef, 1);
      });
    },
    generatePageUrl() {
      if (!this.filtersData) return null;

      // /welhome/results/201/common/types/201-202-203-204/op/0/cont_type/1/from_groups/1/?view=min&sorting=&op=&order=
      const direction = this.filters.direction;
      const data = this.filters[direction];

      if (data.types.length == 0) return null;

      let result = `/welhome/results/${data.types[0]}/common/types/${data.types.join("-")}/op/${data.op == "sale" ? "1" : "2"}/`;

      const priceDelta = (data.priceRange[1] - data.priceRange[0]) * 0.001;
      const hasPrice1 = data.price[0] > data.priceRange[0] + priceDelta;
      const hasPrice2 = data.price[1] < data.priceRange[1] - priceDelta;
      if (hasPrice1 || hasPrice2) {
        result += `price/${hasPrice1 ? Math.round(data.price[0] / this.curCoef) : "0"}-${hasPrice2 ? Math.round(data.price[1] / this.curCoef) : "999999999999"}/`;
      }

      const hasArea1 = data.area[0] > data.areaRange[0] + 1;
      const hasArea2 = data.area[1] < data.areaRange[1] - 1;
      if (hasArea1 || hasArea2) {
        result += `area/${hasArea1 ? Math.round(data.area[0]) : "0"}-${hasArea2 ? Math.round(data.area[1]) : "999999999999"}/`;
      }

      if (direction == 2) {
        const hasAreaSize1 = data.areaSize[0] > data.areaSizeRange[0] + 1;
        const hasAreaSize2 = data.areaSize[1] < data.areaSizeRange[1] - 1;
        if (hasAreaSize1 || hasAreaSize2) {
          result += `areasize/${hasAreaSize1 ? Math.round(data.areaSize[0]) : "0"}-${hasAreaSize2 ? Math.round(data.areaSize[1]) : "999999999999"}/`;
        }
      }
      if (direction == 1 && data.nova) {
        result += `nova/1/`;
      }

      if (this.filters.id) {
        result += `ids/${this.filters.id
          .split(",")
          .map((x) => x.trim())
          .filter((x) => !!x)
          .join("--")}/`;
      }

      let geoFilters = {};

      for (let f of data.geo) {
        geoFilters[f.type] = geoFilters[f.type] || [];
        geoFilters[f.type].push(f.id);
      }

      if (geoFilters.jk?.length) {
        result += `groups/${geoFilters.jk.join("-")}/`;
      }
      if (geoFilters.country?.length) {
        result += `locations/${geoFilters.country.join("-")}/`;
      }
      if (geoFilters.highway?.length) {
        result += `locations/${geoFilters.highway.join("-")}/`;
      }
      if (geoFilters.metro?.length) {
        result += `metro/${geoFilters.metro.join("-")}/`;
      }
      if (geoFilters.area?.length) {
        result += `locations/${geoFilters.area.join("-")}/`;
      }
      if (geoFilters.street?.length) {
        result += `streets/${geoFilters.street.join("--")}/`;
      }
      if (geoFilters.okrug?.length) {
        result += `okrug/${geoFilters.okrug.join("-")}/`;
      }

      return result;
    },
    updateCount() {
      //console.log("update");
      const params = new URLSearchParams();
      params.append("url", this.pageUrl);
      this.isLoading = true;
      axios
        .get("/api_v2/index_search/search_count_for_url/", {
          params: { url: this.pageUrl },
        })
        .then((response) => {
          this.resultsCount = response.data.count;
          this.resultsUrl = response.data.url;
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped lang="less">
@import "../common/style.less";
.isDesktop {
  .Row {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .RowItem {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  .RowItem--Results {
    flex-direction: row;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
  &.Filters {
    width: 944px;
    //background: red;
    margin: 0 auto;
    border-radius: 8px;
    //overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 111;
    margin-top: -82px;
    padding-bottom: 48px;
    margin-bottom: 80px;
  }
  .FiltersContent {
    margin: 30px 48px 0;
  }
  .ResultsCount {
    margin-right: 32px;
  }
}
.Filters {
  //zoom: 2;
  //transform: scale(1.5);
  //transform-origin: 0 0;
}

.isMobile {
  &.Filters {
    /*width: 375px;
    background-color: yellow;
    min-height: 600px;
    transform: scale(650/375);
    transform-origin: 0 0;*/
    margin: 26px * @mobileScale 16px * @mobileScale 0;
  }
  .RowItem,
  .MobileTypeSelect {
    margin-bottom: 16px * @mobileScale;
    //transition: z-index 0s 1s;
  }

  .RowItem.priceOpened {
    z-index: 1;
    position: relative;
    //transition: z-index 0s 0s;
  }
  .ResultsCount {
    text-align: center;
    display: block;
    font: 500 27px/32px @Roboto;
    margin-bottom: 16px * @mobileScale;
  }
}
  .ResultsCount {
    font: 500 16px/19px @Roboto;
    color: @color-black;
    white-space: nowrap;

  }

.PriceCurrency {
  width: 60px;
  margin: -1px;
  &.isOpen {
    z-index: 1000;
  }
  /deep/ .vs__selected-options {
    margin-right: -34px;
    margin-left: -5px;
  }
  /deep/ .SelectViewOption {
    min-width: 15px;

    font: 500 16px/16px Roboto;
  }
  /deep/ .vs__dropdown-menu {
    max-width: 60px !important;

    min-width: 0;
  }
  /deep/ .vs__dropdown-option--selected {
    display: none;
  }
  /deep/ .vs__dropdown-option--highlight {
    color: black !important;
  }
}
</style>
