<template>
  <svg :class="['svg--' + name]">
    <use :xlink:href="static('svg/blocks.sprite.svg#' + name)"></use>
  </svg>
</template>

<script>
export default {
  name: "SvgView",
  props: {
    name: String,
  },
};
</script>
