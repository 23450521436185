<template>
  <form class="CallbackForm" :class="{ isMobile, isDesktop }" id="callback-popup" ref="root">
    <svg-view class="defaulthover" @click.native="back" name="back" />
    <div class="Content" :class="{ autoScaleMobBlock: isMobile }">
      <div class="header">Заказ звонка</div>
      <div class="description">Оставьте номер телефона, <br />и наш сотрудник перезвонит Вам</div>
      <div class="row">
        <masked-input-view v-model="result.phone" placeholder="Телефон" mask="\+\7 (111) 111-11-11" @completed="result.phoneCompleted = $event" :useAutoScaleMob="false" />
      </div>
      <div class="row">
        <select-view class="needsclickroot" placeholder="Способ связи" v-model="result.connectionMethod" :options="stdConnectionMethodsChoices" :autoScaleMob="false" />
      </div>

      <btn :loading="submitting" title="Отправить" :blocked="!completed" :useAutoScaleMob="false" @click.prevent="submit" />
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    submitting: false,

    result: {
      phoneCompleted: false,
      phone: "",
      connectionMethod: "",
    },
  }),

  computed: {
    completed() {
      return this.result.phoneCompleted && !!this.result.connectionMethod;
    },
  },

  methods: {
    back() {
      this.$emit("back");
    },
    submit() {
      let callback = (e) => console.log("Comagic finish e", e);
      Comagic.sitePhoneCall({ phone: this.result.phone, delayed_call_time: Date.now(), group_id: 4757 }, callback);

      this.submitting = true;

      const formData = new FormData();

      let credentials = Comagic.getCredentials();

      let data = {
        num1: this.result.phone,
        num2: "",
        num3: "",
        num4: "",
        method: this.result.connectionMethod,
        ...credentials,
      };
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      axios
        .post("/api/call_me/", formData, { headers: { "Content-Type": "form-data" } })
        .then(() => {
          var dataLayer = (window.dataLayer = window.dataLayer || []);
          var evt = {
            event: "otpravka-formi-" + this.$refs.root.id,
            eventCategory: "lead",
          };
          dataLayer.push(evt);
          console.log({ evt });

          this.$emit("success");
        })
        .catch(() => {
          alert("Не удалось отправить заявку, попробуйте позже!");
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
@import "../common/style.less";

.CallbackForm {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 35px;
}
.row {
  margin-bottom: 16px;
}
.header {
  font: 40px @MysLC;
  margin-bottom: 12px;
}
.description {
  font: 500 18px/24px @Roboto;
  margin-bottom: 30px;
}
.svg--back {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  z-index: 1000;
  fill: @color-primary;
  .CallbackForm.isMobile & {
    transform: scale(@mobileScale);
    transform-origin: 0 0;
    top: @mobileScale*16px;
    left: @mobileScale*16px;
  }
}
.CallbackForm.isMobile .Content {
  height: 644px;
}
</style>
