<template>
  <form
    class="HiddenBaseApp"
    :class="{ isMobile, isDesktop }"
    ref="root"
    :id="formData && formData.id"
  >
    <div class="Content form" v-if="visible && formData">
      <div class="popup-center popup-center-questionnaire no-scroll visible">
        <div class="popup-outer">
          <div class="popup-content">
            <div class="questionnaire current">
              <div class="comments">
                <div class="comment current">
                  <img
                    class="comment-photo"
                    src="/static/front/img/questionnaire/anastasiya.png"
                    srcset="
                      /static/front/img/questionnaire/anastasiya@2x.png 2x
                    "
                  />
                  <div>
                    {{ formData && formData.body }}
                  </div>
                </div>
              </div>
              <div class="questions-outer">
                <div class="questions">
                  <div class="questions-inner">
                    <div class="question current">
                      <validation-observer v-slot="{ handleSubmit, valid }">
                        <div class="Field">
                          <validation-provider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <input-view
                              class="needsclick"
                              type="text"
                              placeholder="Ваше имя"
                              v-model="result.name"
                              :errors="errors"
                            />
                          </validation-provider>
                        </div>
                        <div class="Field">
                          <validation-provider
                            :rules="{
                              required: true,
                              phone: { completed: phoneCompleted },
                            }"
                            v-slot="{ errors }"
                          >
                            <masked-input-view
                              v-model="result.phone"
                              placeholder="Ваш телефон"
                              mask="\+\7 (111) 111-11-11"
                              @completed="phoneCompleted = $event"
                              :errors="errors"
                            />
                          </validation-provider>
                        </div>
                        <div class="Field">
                          <validation-provider
                            rules="required|email"
                            v-slot="{ errors }"
                          >
                            <input-view
                              class="needsclick"
                              type="email"
                              placeholder="Ваш email"
                              v-model="result.email"
                              :errors="errors"
                            />
                          </validation-provider>
                        </div>
                        <div class="Field">
                          <validation-provider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <select-view
                              class="needsclickroot"
                              placeholder="Способ связи"
                              v-model="result.connectionMethod"
                              :options="stdConnectionMethodsChoices"
                              :errors="errors"
                            />
                          </validation-provider>
                        </div>

                        <validation-provider
                          :rules="{ required: { allowFalse: false } }"
                          v-slot="{ errors }"
                          ><div
                            class="form-agreement"
                            :class="{ 'u-ErrorView': errors.length > 0 }"
                          >
                            <label class="nostyle"
                              ><input
                                type="checkbox"
                                v-model="result.comfirmSend"
                              />
                              Нажимая кнопку "Отправить", вы подтверждаете
                              согласие на обработку персональных данных в
                              соответствии с
                              <a
                                class="underline"
                                target="_blank"
                                href="https://www.welhome.ru/disclaimer"
                                >"Положением об обработке персональных данных
                                ООО "ВЭЛХОМ"</a
                              ></label
                            >
                          </div>
                        </validation-provider>
                        <div class="buttons_custom">
                          <btn
                            type="button"
                            class="FinishBtn needsclick"
                            :loading="submitting"
                            :disabled="!valid"
                            :pulse="valid"
                            title="Отправить"
                            @click.prevent="handleSubmit(submit)"
                          />
                        </div>
                      </validation-observer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="close isvg isvg-popup-close defaulthover"
              @click="close"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import MaskedInputView from "../common/components/MaskedInputView.vue";

export default {
  components: { MaskedInputView },

  data: () => ({
    visible: false,
    formData: null,
    loadingData: false,

    submitting: false,

    phoneCompleted: null,

    result: {
      connectionMethod: "",
      phone: "",
      email: "",
      name: "",
      comfirmSend: false,
    },
  }),
  watch: {
    visible(newVal, oldVal) {
      if (newVal && !this.formData && !this.loadingData) {
        this.loadingData = true;
        axios.get("/api_v2/hidden_base/hidden_base_data/").then((response) => {
          this.reset(response.data);
          this.formData = response.data;
        });
      }
    },
  },

  mounted() {
    $("body").on(
      "click",
      ".show-popup[data-popup=popup-center-hidden_base-vue]",
      () => {
        this.toggle(true);
      }
    );

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("questionnaire") == "hiddenbase") {
      this.toggle(true);
    } else {
      if ($("#HiddenBaseApp").attr("autoshow")) {
        this.showIfNeed();
      }
    }
  },

  methods: {
    toggle(visible) {
      this.visible = visible;
    },

    reset() {
      this.submitting = false;
    },

    submit() {
      this.submitting = true;

      const formData = new FormData();

      let credentials = Comagic.getCredentials();

      let data = {
        form_cmd: "hiddenbase",
        name: this.result.name,
        phone: this.result.phone,
        email: this.result.email,
        method: this.result.connectionMethod,
        agreement: "on",
        ...credentials,
      };
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      axios
        .post("/api/submit_form/42116/", formData, {
          headers: {
            "Content-Type": "form-data",
          },
        })
        .then(() => {
          localStorage.setItem("hidden-base-popup--isSubmitted", 1);

          this.visible = false;
          this.reset();

          var dataLayer = (window.dataLayer = window.dataLayer || []);
          var evt = {
            event: "otpravka-formi-" + this.$refs.root.id,
            eventCategory: "lead",
          };
          dataLayer.push(evt);
          console.log({ evt });
        })
        .catch(() => {
          this.submitting = false;
          alert("Не удалось отправить заявку, попробуйте позже!");
        });
    },

    showIfNeed() {
      var latestAppear = localStorage.getItem(
        "hidden-base-popup--latestClosed"
      );
      var isSubmitted = localStorage.getItem("hidden-base-popup--isSubmitted");
      if (
        !isSubmitted &&
        (!latestAppear ||
          Math.abs(new Date() - new Date(parseInt(latestAppear))) >
            1000 * 60 * 10)
      ) {
        setTimeout(() => {
          this.visible = true;
        }, 10000);
      }
    },

    close() {
      localStorage.setItem(
        "hidden-base-popup--latestClosed",
        new Date().getTime()
      );
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="less">
@import "../common/style.less";

.Field {
  margin-top: 16px;
}
</style>
