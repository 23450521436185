var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"root",staticClass:"ObjectSimpleRequestForm",class:{ isMobile: _vm.isMobile, isDesktop: _vm.isDesktop, visible: _vm.visible, thanks: _vm.thanks },attrs:{"id":"object-simple-request-form"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [(!_vm.thanks)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"node clear-block node-webform"},[_c('div',{staticClass:"header row"},[_vm._v("или оставьте заявку:")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-view',{attrs:{"placeholder":"Имя","errors":errors,"border_radius":"0","padding":"12px 15px","fontHeight":"12px/14px"},model:{value:(_vm.result.name),callback:function ($$v) {_vm.$set(_vm.result, "name", $$v)},expression:"result.name"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":{ required: true, phone: { completed: _vm.result.phoneCompleted } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('masked-input-view',{attrs:{"placeholder":"Телефон","mask":"\\+\\7 (111) 111-11-11","errors":errors,"border_radius":"0","padding":"12px 15px","fontHeight":"12px/14px"},on:{"completed":function($event){_vm.result.phoneCompleted = $event}},model:{value:(_vm.result.phone),callback:function ($$v) {_vm.$set(_vm.result, "phone", $$v)},expression:"result.phone"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"connectionMethodBox",class:{ 'u-ErrorView': _vm.hasError }},[_c('div',{staticClass:"connectionMethodBoxTitle"},[_c('span',[_vm._v("Способ связи:")])]),_vm._v(" "),_c('div',{staticClass:"connectionMethodBoxBody"},[_vm._l((_vm.stdConnectionMethodsChoices),function(connectionMethod){return [_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.result.connectionMethod),expression:"result.connectionMethod"}],staticClass:"connectionMethod",attrs:{"type":"radio","id":("connectionMethod-" + (connectionMethod.id))},domProps:{"value":connectionMethod.id,"checked":_vm._q(_vm.result.connectionMethod,connectionMethod.id)},on:{"change":function($event){return _vm.$set(_vm.result, "connectionMethod", connectionMethod.id)}}}),_vm._v(" "),_c('label',{staticClass:"connectionMethodLabel",class:{'active': connectionMethod.id === _vm.result.connectionMethod},attrs:{"for":("connectionMethod-" + (connectionMethod.id))}},[_c('span',{staticClass:"connectionMethodIcon",class:{'active': connectionMethod.id === _vm.result.connectionMethod}},[_c('svg-view',{attrs:{"name":connectionMethod.icon}})],1),_vm._v("\n                      "+_vm._s(connectionMethod.label)+"\n                    ")])])]})],2)])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('btn',{staticClass:"needsclick",attrs:{"type":"button","loading":_vm.submitting,"pulse":valid,"title":"Отправить","btnClasses":"smallButton"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-agreement",class:{ 'u-ErrorView': errors.length > 0 }},[_c('div',{staticClass:"check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.result.comfirmSend),expression:"result.comfirmSend"}],attrs:{"type":"checkbox","id":"confirmSend"},domProps:{"checked":Array.isArray(_vm.result.comfirmSend)?_vm._i(_vm.result.comfirmSend,null)>-1:(_vm.result.comfirmSend)},on:{"change":function($event){var $$a=_vm.result.comfirmSend,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.result, "comfirmSend", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.result, "comfirmSend", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.result, "comfirmSend", $$c)}}}})]),_vm._v(" "),_c('div',[_c('label',{staticClass:"gray-description",attrs:{"for":"confirmSend"}},[_vm._v("Нажимая кнопку \"Отправить\", вы подтверждаете согласие на обработку персональных данных в соответствии с\n                  "),_c('a',{staticClass:"underline",attrs:{"target":"_blank","href":"https://www.welhome.ru/disclaimer"}},[_vm._v("\"Положением об обработке персональных данных ООО \"ВЭЛХОМ\"")])])])])]}}],null,true)})],1)])]):_vm._e()]}}])}),_vm._v(" "),(_vm.thanks)?_c('div',{staticClass:"thanks"},[_c('div',{staticClass:"header"},[_vm._v("Спасибо!")]),_vm._v(" "),_vm._m(0)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"description"},[_vm._v("Ваша заявка отправлена."),_c('br'),_vm._v("Мы свяжемся с вами в ближайшее время.")])}]

export { render, staticRenderFns }