<template>
  <div
    ref="container"
    class="GeoSelectViewWrapper"
    :class="{
      isMobile,
      isDesktop,
      isOpen,
      needScaleFontOnMob: isMobile && isIOS,
    }"
  >
    <div
      v-if="isMobile"
      class="GeoSelectViewWrapperInnerDummy autoScaleMobZoom"
      @click="
        () => {
          this.isOpen = true;
          this.$refs.select.open = true;
        }
      "
    >
      <div class="GeoSelectView u-InputMarginView border">
        <div dir="auto" class="GeoSelectViewInput v-select vs--searchable">
          <div id="vs15__combobox" role="combobox" aria-expanded="false" aria-owns="vs15__listbox" aria-label="Search for option" class="vs__dropdown-toggle">
            <div class="vs__selected-options">
              <span class="span vs__search" :class="{ noOptions: !innerValue.length }">{{ !innerValue.length ? placeholder : innerValue.map((v) => v.label).join(", ") }}</span>
            </div>
            <div class="vs__actions">
              <span class="custom-open-indicator"
                ><svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="open_indicator">
                  <path d="M1 1L7 7L13 1" stroke-width="2"></path></svg
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="GeoSelectViewWrapperInner" :class="{ autoScaleMobZoom: isMobile }" :style="{ display: !isMobile || isOpen ? null : 'none' }">
      <div
        class="GeoSelectViewMobClose"
        v-if="isMobile && isOpen"
        @click="
          () => {
            this.$refs.select.open = false;
            this.isOpen = false;
          }
        "
      >
        <svg-view class="svg-close" name="close-geo" />
      </div>
      <div class="GeoSelectView u-InputMarginView" :class="[modes.map((m) => `GeoSelectView--${m}`), { 'u-ErrorView': hasError, border }]">
        <label class="GeoSelectViewLabel" v-if="!!title">{{ title }}</label>
        <v-select
          ref="select"
          class="GeoSelectViewInput"
          v-model="innerValue"
          :autoscroll="false"
          :options="currentOptions"
          :placeholder="placeholder"
          :max-height="maxHeight"
          :clearable="false"
          :searchable="true"
          :multiple="multiple"
          :clearSearchOnSelect="true"
          :clearSearchOnBlur="() => true && !debugMenu"
          :closeOnSelect="!isMobile"
          :selectable="(option) => !option.header"
          :filter="filter"
          :getOptionKey="(option) => 'aa' + option.id + option.label"
          @search:blur="
            () => {
              searchQuery = debugMenu ? searchQuery : '';
              if (this.isMobile) {
                this.$refs.select.open = true;
              } else {
              }
            }
          "
          @search:focus="isOpen = true"
          @open="isOpen = true"
          @close="isOpen = false"
          :transition="isMobile ? '' : 'vs__fade'"
        >
          <template slot="option" slot-scope="option">
            <div v-if="!option.header" @mousedown.prevent.stop="toggleOption(option)">
              <!--<svg-view class="svg-type" :name="'geo-' + option.type + (option.type == 'jk' ? '-' + direction : '')" :style="{ fill: option.iconColor }" />-->
              <span class="icomoon icomoon-type" :class="'icomoon-geo-' + option.type + (option.type == 'jk' ? '-' + direction : '')" :style="{ color: option.iconColor }" />
              <span class="GeoSelectViewOption" v-html="option.label"></span>
              <span class="icomoon icomoon-checked icomoon-option-selected-indicator" />
              <!--<svg-view class="svg-checked" name="option-selected-indicator" />-->
            </div>
            <div v-if="option.header">
              <span class="GeoSelectViewOptionHeader" v-html="option.label" />
            </div>
          </template>
          <template slot="open-indicator">
            <span class="custom-open-indicator">
              <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="open_indicator">
                <path d="M1 1L7 7L13 1" stroke-width="2" />
              </svg>
            </span>
          </template>
          <template slot="list-header">
            <div>
              <div class="GeoSelectViewSelectedOption needsfastclick needsfastclickroot" v-for="option in innerValue" :key="'x' + option.id + option.label">
                <!--<svg-view class="svg-type" :name="'geo-' + option.type + (option.type == 'jk' ? '-' + direction : '')" :style="{ fill: option.iconColor }"></svg-view>-->
                <span class="icomoon icomoon-type" :class="'icomoon-geo-' + option.type + (option.type == 'jk' ? '-' + direction : '')" :style="{ color: option.iconColor }" />
                <span class="title" v-html="option.label"></span>
                <span class="close" @click="$refs.select.deselect(option)">
                  <!--<svg-view class="svg-close" name="close"></svg-view>-->
                  <span class="icomoon icomoon-close" />
                </span>
              </div>
            </div>
            <div v-if="!solidMode" class="HeaderSelect">
              <span
                v-for="item in types"
                :key="item.id"
                class="HeaderSelectItem needsfastclick needsfastclickroot"
                :class="{
                  'HeaderSelectItem--selected': item.id == selectedType,
                }"
                @click="selectedType = item.id"
                >{{ item.label }}</span
              >
            </div>
          </template>
          <template slot="search" slot-scope="{ attributes, events }">
            <input class="vs__search" :class="{ noOptions: !innerValue.length }" v-bind="attributes" v-on="events" @input="searchQuery = $event.target.value" @keydown.8="innerValue.pop()" />
          </template>
          <template slot="no-options" slot-scope="{ search, searching, loading }">
            <div class="NoOptions">Ничего не найдено</div>
          </template>
          <template slot="selected-option-container" slot-scope="{ option, deselect, multiple, disabled }">
            {{ option.label + (innerValue[innerValue.length - 1] === option ? "" : ", ") }}
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import isObject from "lodash/isObject";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  name: "GeoSelectView",
  components: {
    vSelect,
  },
  props: {
    direction: { type: Number, default: 1 },
    title: { type: String, default: "" },
    value: { type: Array, default: () => [] },
    placeholder: { type: String, default: "" },
    modes: { type: Array, default: () => [] },
    maxHeight: { type: String, default: "400px" },
    multiple: { type: Boolean, default: true },
    border: { type: Boolean, default: true },
    debugMenu: { type: Boolean, default: false },

    opts: {
      type: Object,
      default: () => ({ area: [], street: [], metro: [], jk: [], highway: [] }),
    },
    types: { type: Array, default: () => [] },
  },
  computed: {
    currentOptions() {
      if (!this.solidMode) return this.opts[this.selectedType];

      let res = [];

      if (this.opts.okrug?.length) {
        res = [...res, { id: "o--", label: "Округи", type: "okrug", header: true }, ...this.opts.okrug];
      }
      if (this.opts.area?.length) {
        res = [...res, { id: "a--", label: "Районы", type: "area", header: true }, ...this.opts.area];
      }
      if (this.opts.street?.length) {
        res = [...res, { id: "s--", label: "Улицы", type: "street", header: true }, ...this.opts.street];
      }
      if (this.opts.metro?.length) {
        res = [...res, { id: "m--", label: "Метро", type: "metro", header: true }, ...this.opts.metro];
      }
      if (this.opts.highway?.length) {
        res = [...res, { id: "h--", label: "Шоссе", type: "highway", header: true }, ...this.opts.highway];
      }
      if (this.opts.jk?.length) {
        res = [...res, { id: "jk--", label: "ЖК", type: "jk", header: true }, ...this.opts.jk];
      }

      return res;
    },
    solidMode() {
      return !!this.searchQuery;
    },
  },
  data: () => ({
    container: null,
    isOpen: false,
    select: null,
    xtypes: [
      { label: "Округ", id: "okrug" },
      { label: "Район", id: "area" },
      { label: "Улица", id: "street" },
      { label: "Метро", id: "metro" },
      { label: "ЖК", id: "jk" },
    ],
    selectedType: null,
    searchQuery: "",

    interval: null,
  }),
  mounted() {
    if (this.debugMenu || 0) {
      this.interval = setInterval(() => {
        this.$refs.select.open = true;
      }, 100);
    }
    this.value && this.value.$touch && this.value.$touch();
    this.selectedType = this.types?.[0].id;
    /*//this.container.addEventListener()
    this.container.addEventListener("touchmove", this.blockOwnTouchMove, {
      passive: false,
    });
    this.container.addEventListener("touchstart", this.blockOwnTouchMove, {
      passive: false,
    });
    this.container.addEventListener("touchend", this.blockOwnTouchMove, {
      passive: false,
    });*/
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    filter(options, search) {
      var hasTypes = {};
      options.filter((option) => {
        if (option.header) return;
        if (this.$refs.select.filterBy(option, option.label, search)) {
          hasTypes[option.type] = true;
        }
      });

      return options.filter((option) => {
        if (option.header && hasTypes[option.type]) return true;
        return this.$refs.select.filterBy(option, option.label, search);
      });
    },
    toggleOption(option) {
      if (this.$refs.select.selectable(option)) {
        if (this.$refs.select.isOptionSelected(option)) {
          this.$refs.select.deselect(option);
        } else {
          this.$refs.select.select(option);
        }
      }
    },
    blockBodyTouchMove: (e) => {
      //console.log("c");
      /*if ($(e.target).closest(".GeoSelectViewWrapper").length == 0) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        console.log("c");
      }*/
    },
    blockOwnTouchMove: (e) => {
      //console.log("c");
      //e.stopPropagation();
    },
  },
  watch: {
    types() {
      this.selectedType = this.types[0].id;
    },
    isOpen() {
      if (this.isOpen) {
        disableBodyScroll(this.$refs.containerm, {
          reserveScrollBarGap: true,
          allowTouchMove: (el) => $(el).closest(".GeoSelectViewWrapper").length > 0,
        });
      } else {
        enableBodyScroll(this.$refs.container);
      }
      /*if (this.isMobile) {
        if (this.isOpen) {
          $Body[0].addEventListener("touchmove", this.blockBodyTouchMove, {
            passive: false,
          });
          $Body[0].addEventListener("touchstart", this.blockBodyTouchMove, {
            passive: false,
          });
          $Body[0].addEventListener("touchend", this.blockBodyTouchMove, {
            passive: false,
          });
        } else {
          //$Body.off("touchmove", this.blockBodyTouchMove);
          $Body[0].removeEventListener("touchmove", this.blockBodyTouchMove);
          $Body[0].removeEventListener("touchstart", this.blockBodyTouchMove);
          $Body[0].removeEventListener("touchend", this.blockBodyTouchMove);
        }
        //$Body.toggleClass("BlockedByIndexGeo", this.isOpen);
      }*/
    },
  },
};

$("body").on("focus", function(e) {
  e.preventDefault();
  console.log("1");
});
</script>

<style lang="less">
body.BlockedByIndexGeo {
  overflow: hidden;
}
* {
  -webkit-overflow-scrolling: auto;
}

.GeoSelectViewWrapper.isMobile {
  transition: none !important;
  transition-delay: none !important;
  backface-visibility: hidden !important;
}
</style>
<style scoped lang="less">
@import "vue-select/dist/vue-select.css";
@import "../../common/style.less";

.GeoSelectViewWrapper.isMobile {
  height: 85px;
  position: relative;
  /deep/ * {
    transition: none !important;
    transition-delay: none !important;
    backface-visibility: hidden !important;
  }
  &.isOpen /deep/ .vs__actions {
    display: none;
  }
  /deep/ .vs__fade-enter-active,
  /deep/ .vs__fade-leave-active {
    transition: none;
  }
  &.isOpen .GeoSelectViewWrapperInner {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    background: white;
    display: flex;
    flex-direction: row;
    padding: 12px 16px 12px 0;
    height: auto;
    box-sizing: border-box;

    .GeoSelectView {
      flex: 1;
    }
  }
  &.isOpen .GeoSelectViewWrapperInnerDummy {
    visibility: hidden;
    pointer-events: none;
  }
  &.isOpen /deep/ .vs__dropdown-toggle {
    z-index: 1001;
    //margin-left: 40px;
    //max-width: calc(100% - 130px);
  }

  /deep/ .vs__dropdown-menu {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    //max-height: 400px;
    width: auto;
    max-height: none !important;
    height: auto !important;
    //height: calc(100vh - 60px);
    padding-bottom: 330px;
    box-sizing: border-box;
    background: white;
    border-radius: 0;
  }

  .GeoSelectViewMobClose {
    padding: 12px 12px 10px 16px;
    .svg-close {
      width: 24px;
      height: 24px;
    }
  }
}

.HeaderSelect {
  font: 500 14px/20px @Roboto;
  .GeoSelectViewWrapper.needScaleFontOnMob & {
    font: 500 24px / 35px @Roboto;
  }
  color: @color-unselected;
  padding-left: 8px;
  border-bottom: 1px solid @color-border-gray;
}
.HeaderSelectItem {
  cursor: pointer;
  padding: 12px 12px 8px;
  display: inline-block;
  &:hover {
    color: @color-black;
  }
  &--selected {
    color: @color-primary !important;
    border-bottom: 2px solid @color-primary;
    margin-bottom: -1px;
  }
}

.GeoSelectViewSelectedOption {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  border-bottom: 1px solid rgba(230, 230, 230, 0.2);
  .title {
    font: normal 13px/15px @Roboto;
    .GeoSelectViewWrapper.needScaleFontOnMob & {
      font: normal (13px * @mobileScale) / (15px * @mobileScale) @Roboto;
    }
    color: @color-primary;
    flex: 1;

    //max-width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    padding-right: 10px;
  }

  .close {
    display: inline-block;
    padding: 4px;
    margin: -7px;
    cursor: pointer;

    &:hover {
      .icomoon-close {
        color: @color-black;
      }
    }
  }
  .icomoon-type {
    width: 24px;
    height: 24px;
    margin: -6px 2px -4px -2px;
    color: @color-primary;
    display: inline-block;
    font-size: 24px;
    .GeoSelectViewWrapper.needScaleFontOnMob & {
      font-size: 24px * @mobileScale;
    }
  }
  .icomoon-close {
    width: 16px;
    height: 16px;
    color: @color-light-text;
    display: inline-block;
    font-size: 16px;
    .GeoSelectViewWrapper.needScaleFontOnMob & {
      font-size: 16px * @mobileScale;
      //margin-bottom: 4px;
      //background: yellow;
    }
  }
}

.GeoSelectViewOptionHeader {
  font: 500 10px/12px @Roboto;
  .GeoSelectViewWrapper.needScaleFontOnMob & {
    font: 500 (10px * @mobileScale) / (12px * @mobileScale) @Roboto;
  }
  text-transform: uppercase;
  letter-spacing: 1px;
  color: @color-light-text;
  position: relative;
  top: 8px;
}

.GeoSelectView {
  position: relative;
}

.GeoSelectViewLabel {
  display: block;
  font: bold 18px/30px @Roboto;

  .GeoSelectViewWrapper.needScaleFontOnMob & {
    font: bold (18px * @mobileScale) / (30px * @mobileScale) @Roboto;
  }
  letter-spacing: -0.025em;
}
.GeoSelectView:not(.border) .GeoSelectViewInput /deep/ .vs__dropdown-toggle {
  border-color: transparent !important;
  border-radius: 0 !important;
  background: transparent;
}
.GeoSelectViewInput {
  /deep/ .vs__dropdown-toggle {
    border: 1px solid @color-border-gray;
    border-radius: 4px;
    padding: 1px;
    font: 500 14px/16px @Roboto;
    .GeoSelectViewWrapper.needScaleFontOnMob & {
      font: 500 (14px * @mobileScale) / (16px * @mobileScale) @Roboto;
    }
    transition: border-color 0.3s;
    z-index: 10;
    background: white;
    position: relative;
  }

  &.vs--open /deep/ .vs__dropdown-toggle {
    padding: 0;
    border: 2px solid #c0ac9c;
  }
  /deep/ .vs__dropdown-menu {
    overflow: auto !important;
    padding: 0;
    background: white;

    .GeoSelectViewWrapper.isDesktop & {
      box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
    }
    .GeoSelectViewWrapper.needScaleFontOnMob & {
      box-shadow: none;
    }
    border-radius: 4px;
    margin-top: 4px;
    border: none;
  }

  /deep/ .vs__dropdown-menu li[role="option"] {
    padding: 0;
    background: none;
    color: #3c4043;
    font: 13px/15px @Roboto;
    .GeoSelectViewWrapper.needScaleFontOnMob & {
      font: normal (13px * @mobileScale) / (15px * @mobileScale) @Roboto;
    }
    padding: 13px 16px 12px;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(230, 230, 230, 0.2);
    }

    &.vs__dropdown-option--highlight {
      background: linear-gradient(0deg, rgba(230, 230, 230, 0.2), rgba(230, 230, 230, 0.2)), #ffffff;
    }
    &.vs__dropdown-option--selected {
      color: #b09783;
    }
  }
  .open_indicator {
    transition: transform 0.3s;
    stroke: #7e7e7e;
  }
  &.vs--open /deep/ .open_indicator {
    transform: rotate(180deg);
    stroke: @color-primary;
  }

  /deep/ .vs__no-options {
    letter-spacing: 0;
    font: normal 12px / 30px @Roboto;
    color: @color-light-text;

    .GeoSelectViewWrapper.needScaleFontOnMob & {
      font: normal (12px * @mobileScale) / (30px * @mobileScale) @Roboto;
    }
  }
  /deep/ .vs__selected-tag {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }
  /deep/ .vs__selected-options {
    padding: 15px;
    white-space: nowrap;
    overflow: hidden;
    //display: inline-block;
    text-overflow: ellipsis;
    max-height: 46px;
    .GeoSelectViewWrapper.needScaleFontOnMob & {
      padding: 9px 15px;
    }
  }
  /deep/ .vs__selected-options input {
    //min-width: 100px;
    margin-left: 10px;
    &.noOptions {
      margin-left: 0;
    }
  }
  /deep/ .vs__selected {
    color: #3c4043;
    padding: 0;
    margin: 0;
    border: none;
    position: relative;
    opacity: 1;
    background: none;
    font: 500 14px/16px @Roboto;
    .GeoSelectViewWrapper.needScaleFontOnMob & {
      font: 500 (14px * @mobileScale) / (16px * @mobileScale) @Roboto;
    }

    margin-right: 10px;

    & + .vs__selected {
      margin-left: -10px;
      &:before {
        content: ",  ";
        margin-right: 5px;
      }
    }
  }
  /deep/ .vs__search {
    color: #3c4043;
    padding: 0;
    margin: 0;
    border: none;
    position: relative;
    opacity: 1;
    font: 500 14px/16px @Roboto;
    .GeoSelectViewWrapper.needScaleFontOnMob & {
      font: 500 (14px * @mobileScale) / (16px * @mobileScale) @Roboto;
    }
    &::placeholder {
      color: @color-light-text;
    }
    &:focus::placeholder {
      opacity: 1;
      color: @color-border-gray;
    }
    &.span {
      &.noOptions {
        color: @color-light-text;
      }
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  /deep/ .vs__dropdown-option {
    .icomoon-checked {
      float: right;
      width: 14px;
      height: 10px;
      font-size: 14px;
      .GeoSelectViewWrapper.needScaleFontOnMob & {
        font-size: 14px * @mobileScale;
      }
      display: inline-block;
      fill: red;
      margin: 0px 0 -4px 0;
      vertical-align: bottom;
    }

    .icomoon-type {
      width: 24px;
      height: 24px;
      margin: -6px 2px -1px -2px;
      color: @color-light-text;
      font-size: 24px;
      .GeoSelectViewWrapper.needScaleFontOnMob & {
        font-size: 24px * @mobileScale;
        margin-bottom: 1px;
      }
      display: inline-block;
    }
  }
  /deep/ .vs__dropdown-option:not(.vs__dropdown-option--selected) {
    .icomoon-checked {
      display: none;
    }
  }
  /deep/ .vs__dropdown-option.vs__dropdown-option--highlight {
    .icomoon-type {
      color: @color-unselected;
    }
  }
  /deep/ .vs__dropdown-option.vs__dropdown-option--selected {
    .icomoon-type {
      color: @color-primary;
    }
  }
  /deep/ .vs__actions {
    padding: 0;
  }
}

.custom-open-indicator {
  padding: 0 19px;
}
.option-selected-indicator {
  float: right;
}

.GeoSelectViewLabel + .GeoSelectViewInput {
  margin: @margin-label-input 0 0;
}

.GeoSelectViewEdit {
  position: absolute;
  top: 3px;
  right: 0;
  font: 12px/30px @Roboto;
  .GeoSelectViewWrapper.needScaleFontOnMob & {
    font: 500 (12px * @mobileScale) / (30px * @mobileScale) @Roboto;
  }
  color: @color-primary;
  transition: opacity 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.GeoSelectViewOption {
  pointer-events: none;

  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
</style>
