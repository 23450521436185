<template>
  <div class="FilterDirectionSwitcherDesktop">
    <span
      v-for="index in 3"
      :key="index"
      :class="['tab', index == value && 'current']"
      @click="onSelect(index)"
      >{{ titles[index] }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    value: { type: [Number], default: 1 },
  },
  data: () => ({
    titles: [
      "--",
      "Городская недвижимость",
      "Загородная недвижимость",
      "Зарубежная недвижимость",
      "Офисная недвижимость",
      "Торговая недвижимость",
    ],
  }),
  methods: {
    onSelect(direction) {
      this.$emit("input", direction);
    },
  },
};
</script>

<style scoped lang="less">
.FilterDirectionSwitcherDesktop {
  display: flex;
  justify-content: space-between;
  text-align: right;
  padding: 0 48px;
  border-bottom: 1px solid #e6e6e6;
}
.tab {
  cursor: pointer;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #7e7e7e;
  border-bottom: 3px solid transparent;
  padding: 32px 0 27px;
  margin-bottom: -1px;
  text-align: center;

  &:hover {
    color: #3c4043;
  }
  &.current {
    color: #b09783;
    border-bottom-color: #b09783;
  }
}
</style>
