var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"root",staticClass:"HiddenBaseApp",class:{ isMobile: _vm.isMobile, isDesktop: _vm.isDesktop },attrs:{"id":_vm.formData && _vm.formData.id}},[(_vm.visible && _vm.formData)?_c('div',{staticClass:"Content form"},[_c('div',{staticClass:"popup-center popup-center-questionnaire no-scroll visible"},[_c('div',{staticClass:"popup-outer"},[_c('div',{staticClass:"popup-content"},[_c('div',{staticClass:"questionnaire current"},[_c('div',{staticClass:"comments"},[_c('div',{staticClass:"comment current"},[_c('img',{staticClass:"comment-photo",attrs:{"src":"/static/front/img/questionnaire/anastasiya.png","srcset":"/static/front/img/questionnaire/anastasiya@2x.png" + " 2x"}}),_vm._v(" "),_c('div',[_vm._v("\n                  "+_vm._s(_vm.formData && _vm.formData.body)+"\n                ")])])]),_vm._v(" "),_c('div',{staticClass:"questions-outer"},[_c('div',{staticClass:"questions"},[_c('div',{staticClass:"questions-inner"},[_c('div',{staticClass:"question current"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('div',{staticClass:"Field"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-view',{staticClass:"needsclick",attrs:{"type":"text","placeholder":"Ваше имя","errors":errors},model:{value:(_vm.result.name),callback:function ($$v) {_vm.$set(_vm.result, "name", $$v)},expression:"result.name"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"Field"},[_c('validation-provider',{attrs:{"rules":{
                            required: true,
                            phone: { completed: _vm.phoneCompleted },
                          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('masked-input-view',{attrs:{"placeholder":"Ваш телефон","mask":"\\+\\7 (111) 111-11-11","errors":errors},on:{"completed":function($event){_vm.phoneCompleted = $event}},model:{value:(_vm.result.phone),callback:function ($$v) {_vm.$set(_vm.result, "phone", $$v)},expression:"result.phone"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"Field"},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input-view',{staticClass:"needsclick",attrs:{"type":"email","placeholder":"Ваш email","errors":errors},model:{value:(_vm.result.email),callback:function ($$v) {_vm.$set(_vm.result, "email", $$v)},expression:"result.email"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"Field"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('select-view',{staticClass:"needsclickroot",attrs:{"placeholder":"Способ связи","options":_vm.stdConnectionMethodsChoices,"errors":errors},model:{value:(_vm.result.connectionMethod),callback:function ($$v) {_vm.$set(_vm.result, "connectionMethod", $$v)},expression:"result.connectionMethod"}})]}}],null,true)})],1),_vm._v(" "),_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('div',{staticClass:"form-agreement",class:{ 'u-ErrorView': errors.length > 0 }},[_c('label',{staticClass:"nostyle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.result.comfirmSend),expression:"result.comfirmSend"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.result.comfirmSend)?_vm._i(_vm.result.comfirmSend,null)>-1:(_vm.result.comfirmSend)},on:{"change":function($event){var $$a=_vm.result.comfirmSend,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.result, "comfirmSend", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.result, "comfirmSend", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.result, "comfirmSend", $$c)}}}}),_vm._v("\n                            Нажимая кнопку \"Отправить\", вы подтверждаете\n                            согласие на обработку персональных данных в\n                            соответствии с\n                            "),_c('a',{staticClass:"underline",attrs:{"target":"_blank","href":"https://www.welhome.ru/disclaimer"}},[_vm._v("\"Положением об обработке персональных данных\n                              ООО \"ВЭЛХОМ\"")])])])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"buttons_custom"},[_c('btn',{staticClass:"FinishBtn needsclick",attrs:{"type":"button","loading":_vm.submitting,"disabled":!valid,"pulse":valid,"title":"Отправить"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}})],1)]}}],null,false,582412132)})],1)])])])]),_vm._v(" "),_c('span',{staticClass:"close isvg isvg-popup-close defaulthover",on:{"click":_vm.close}})])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }