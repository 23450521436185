<template>
  <div class="RangeSliderWrapper" :class="{ autoScaleMob: isMobile, isMobile }">
    <div class="RangeSlider">
      <div class="RangeSlider-inner">
        <div class="RangeSlider-content">
          <div v-if="!inputMode" class="RangeSlider-content--simple" @click="focus($event, 1)">
            <label>{{ label }}</label>
            <span v-if="min != null && max != null"
              ><span @click="focus($event, 1)">{{ shortValue(innerValue[0]) }}</span> — <span @click="focus($event, 2)">{{ shortValue(innerValue[1]) }}{{ unit ? " " + unit : "" }}</span>
            </span>
          </div>
          <div v-if="inputMode" class="RangeSlider-content--manual">
            <div class="RangeSlider-content--manual-item">
              <label :for="_uid + 'input1'">от</label>
              <input
                ref="input1"
                :id="_uid + 'input1'"
                class="RangeSlider-input"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                @blur="checkBlur(0)"
                @keypress="isNumber($event)"
                :value="inputValues[0]"
                @input="input(0, $event)"
                :placeholder="roundedMin"
                @keydown.8="checkClear(1)"
              />
            </div>
            <div class="RangeSlider-content--manual-item">
              <label :for="_uid + 'input2'">до</label>
              <input
                ref="input2"
                :id="_uid + 'input2'"
                class="RangeSlider-input"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                @blur="checkBlur(1)"
                @keypress="isNumber($event)"
                :value="inputValues[1]"
                @input="input(1, $event)"
                :placeholder="roundedMax"
                @keydown.8="checkClear(2)"
              />
            </div>
          </div>
        </div>
        <vue-slider
          v-if="!inputMode && min != null && max != null"
          class="RangeSlider-slider"
          :class="{ isMobile }"
          v-model="roundedValue"
          :enable-cross="false"
          :min="roundedMin"
          :max="roundedMax"
          :dotSize="isMobile ? 32 : 18"
          :dot-options="dotOptions"
          :lazy="false"
          :height="isMobile ? 2 : 1"
          :process-style="{ backgroundColor: '#b09783' }"
          :rail-style="{ backgroundColor: 'transparent' }"
        >
          <div class="Dot" :class="{ isMobile }" slot="dot"></div>
        </vue-slider>
      </div>
      <div class="RangeSlider-separator"></div>
      <slot name="customcomponent"></slot>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "RangeSlider",
  components: { VueSlider },
  props: {
    value: { type: Array, default: () => [0, 1000000] },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 3000001 },
    label: { type: String, default: "Цена" },
    unit: { type: String, default: "" },
  },

  data() {
    return {
      inputMode: false,
      input1: null,
      input2: null,
      inputValues: [0, 0],
      needClearOnBs1: false,
      needClearOnBs2: false,
    };
  },

  computed: {
    dotOptions() {
      return {
        tooltip: "none",
      };
    },
    roundedValue: {
      get() {
        return [Math.ceil(this.innerValue[0]), Math.floor(this.innerValue[1])];
      },
      set(newVal) {
        this.innerValue = newVal;
      },
    },
    roundedMin() {
      return Math.floor(this.min);
    },
    roundedMax() {
      return Math.ceil(this.max);
    },
  },
  methods: {
    checkClear(num) {
      if (num == 1 && this.needClearOnBs1) {
        this.needClearOnBs1 = false;
        this.$set(this.inputValues, 0, "");
        console.log("clear 1");
      }
      if (num == 2 && this.needClearOnBs2) {
        this.needClearOnBs2 = false;
        this.$set(this.inputValues, 1, "");
        console.log("clear 2");
      }
    },
    shortValue(val) {
      val = parseInt(((Math.round(val) || "") + "").replace(/[^\d|-]+/g, ""), 10) || 0;
      if (val <= 999) return val;
      if (val <= 9990) return Math.round(val / 100) / 10 + " тыс";
      if (val <= 999000) return Math.round(val / 1000) + " тыс";
      if (val <= 9990000) return Math.round(val / 100000) / 10 + " млн";
      if (val <= 999000000) return Math.round(val / 1000000) + " млн";
      if (val <= 9990000000) return Math.round(val / 100000000) / 10 + " млрд";
      if (val <= 999000000000) return Math.round(val / 1000000000) + " млрд";
      if (val <= 9990000000000) return Math.round(val / 100000000000) / 10 + " трлн";
      return Math.round(val / 1000000000) + " млрд";
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    input(num, $event) {
      if (!$event.target.value) return;
      let value = parseInt($event.target.value, 10);

      this.$set(this.inputValues, num, value || "");
      this.$set(this.innerValue, num, value || 0);
    },
    focus(e, num) {
      e.stopPropagation();
      this.inputValues = [...this.roundedValue];
      this.inputMode = true;
      this.needClearOnBs1 = true;
      this.needClearOnBs2 = true;
      this.$nextTick(() => {
        this.$refs["input" + num].focus();
      });
    },
    checkBlur(num) {
      setTimeout(() => {
        if (document.activeElement != this.$refs.input1 && document.activeElement != this.$refs.input2) {
          this.inputMode = false;
          let newValues = this.innerValue.map((x) => parseInt(x, 10) || 0);
          for (let rep = 0; rep < 2; rep++)
            for (let i = 0; i <= 1; i++) {
              let value = newValues[i];
              value = Math.min(value, this.max);
              value = Math.max(value, this.min);
              if (i == 0) value = Math.min(value, newValues[1]);
              if (i == 1) value = Math.max(value, newValues[0]);
              newValues[i] = value;
            }
          this.innerValue = newValues;
        }
      }, 100);
    },
  },
  created() {},
};
</script>

<style scoped lang="less">
@import "../style.less";
.RangeSlider * {
  backface-visibility: hidden;
}
.RangeSlider {
  border: 1px solid @color-border-gray;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  background: white;
}
.RangeSlider-inner {
  position: relative;
  flex: 1;
}
.RangeSlider-separator {
  display: none;
  &:not(:last-child) {
    display: block;
    width: 0;
    height: 30px;
    border-right: 1px solid @color-border-gray;
    margin: 9px 0;
    //border-right: 1px solid @color-border-gray;
  }
}
.RangeSlider-content {
  font: normal 15px/18px @Roboto;
  color: @color-light-text;

  label {
    margin-right: 11px;
    color: @color-black;
  }
}
.RangeSlider-slider {
  position: absolute;
  bottom: 0;
  left: 18px;
  right: 18px;
  margin-bottom: -10px;

  &.isMobile {
    margin-bottom: -25px;
    right: auto;
    width: calc((100% - 36px) * @mobileScale) !important;
    transform: scale(1 / @mobileScale);
    transform-origin: 0 0;
  }
}
.RangeSlider-content--simple {
  padding: 15px;
}
.RangeSlider-content--manual {
  padding: 0 0 0 15px;
  display: flex;
  //flex-direction: row;
  label {
    margin-right: 7px;
  }
  div {
    //flex: 1;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  input {
    flex: 1;
    font: inherit;
    padding: 15px 0 15px 0px;
    border: none;
    margin: 0;
    min-width: 0;
    width: 0;
    &:focus::placeholder {
      opacity: 1;
      color: @color-border-gray;
    }
  }
}
.Dot {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  border: 3px solid #fff;
  margin: 3px;
  background: @color-primary;
  cursor: pointer;
  position: relative;

  &.isMobile:after {
    content: " ";
    position: absolute;
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
  }

  .RangeSlider-slider:hover & {
    width: 12px;
    height: 12px;
    border: 3px solid #fff;
    margin: 0;
  }

  &:active {
    width: 16px !important;
    height: 16px !important;
    border: 1px solid #fff !important;
    margin: 0 !important;
  }

  &.isMobile {
    width: 12px * @mobileScale !important;
    height: 12px * @mobileScale !important;
    border: 3px * @mobileScale solid #fff !important;
    margin: 0;

    &:active {
      width: 16px * @mobileScale !important;
      height: 16px * @mobileScale !important;
      border: 1px * @mobileScale solid #fff !important;
      margin: 0 !important;
    }
  }
}
</style>
