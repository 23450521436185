<template>
  <div :class="{ autoScaleMob: isMobile }">
    <input class="InputView" v-model="innerValue" :placeholder="placeholder" :type="type" :class="{ 'u-ErrorView': hasError }" :style="styleObject"/>
  </div>
</template>

<script>
export default {
  name: "InputView",
  props: {
    value: { type: String, default: "" },
    type: { type: String, default: "text" },
    placeholder: { type: String, default: "" },
    errors: { type: Array, default: () => [] },
    border_radius: { type: String, default: "4px" },
    padding: { type: String, default: "16px 15px" },
    fontHeight: { type: String, default: "14px/16px" },
  },
  computed: {
    styleObject () {
      return {
        'border-radius': this.border_radius,
        padding: this.padding,
        font: '500 ' + this.fontHeight + '@Roboto',
      }
    }
  },
};
</script>

<style scoped lang="less">
@import "../style.less";
.InputView {
  display: block;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  box-shadow: none;
  border: 1px solid @color-border-gray;
  border-radius: 4px;
  padding: 16px 15px;
  outline: none;
  font: 500 14px/16px @Roboto;
  color: @color-black;
  outline: none;
  appearance: none;
  margin: 0;

  &::placeholder {
    color: @color-light-text;
  }
  &:focus::placeholder {
    opacity: 1;
    color: @color-border-gray;
  }
  &:focus {
    border: 2px solid @color-primary;
    padding: 15px 14px;
  }
}
.u-ErrorView {
  border-color: red;
}
</style>
