<template>
  <div class="BurgerMenuApp">
    <div class="Content" v-if="needRender">
      <div class="burgerMenu" :class="{ overflowhidden }">
        <a class="isvg isvg-logo defaulthover" href="https://www.welhome.ru/"></a>
        <div class="burgerMenu-topLinks">
          <a v-for="topItem in menuData.menuTop" :key="topItem.title" :href="topItem.link">{{ topItem.title }}</a>
        </div>
        <div class="burgerMenu-menuContent">

          <div class="burgerMenu-opTypes">
            <span class="item" :class="{ current: opType == 'sale' }" @click="opType = 'sale'">Купить</span>
            <span class="item" :class="{ current: opType == 'rent' }" @click="opType = 'rent'">Арендовать</span>
          </div>

          <div class="burgerMenu-directions">
            <span class="item" :class="{ current: direction == '1' }" @click="direction = '1'">Городская недвижимость</span>
            <span class="item" :class="{ current: direction == '2' }" @click="direction = '2'">Загородная недвижимость</span>
            <span class="item" :class="{ current: direction == '3' }" @click="direction = '3'">Офисная недвижимость</span>
            <div class="sep"></div>
            <span class="item" :class="{ current: direction == '4' }" @click="direction = '4'">Торговая недвижимость</span>
            <span class="item" :class="{ current: direction == '5' }" @click="direction = '5'">Зарубежная недвижимость</span>
            <a class="item" href="https://www.welhome.ru/zayavka-na-prodaju-i-sdacu-nedvigimosti-v-arendu">Продать/cдать в аренду</a>
          </div>

          <div class="burgerMenu-content">
            <div class="burgerMenu-page">
              <div class="burgerMenu-page-columns">
                <div class="column" v-for="column in this.columnsData" :key="column.id">
                  <div v-for="group in column.items" :key="group.title" class="burgerMenu-group dontsplit" :class="{ collapsed: !group.expanded }">
                    <div class="burgerMenu-group-title" @click="group.expanded = !group.expanded">
                      {{ group.title }}
                      <span class="icon isvg isvg-burger-more"></span>
                    </div>
                    <div class="burgerMenu-group-items">
                      <div v-for="item in group.children" :key="item.link" class="burgerMenu-item" :class="{ hidden: !item.default }">
                        <a :href="item.link">{{ item.title }}</a>
                      </div>
                      <div class="burgerMenu-group-more" @click="group.children.forEach((x) => (x.default = true))">Еще<span class="icon isvg isvg-burger-more"></span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  computed: {
    needRender() {
      return this.visible && !!this.menuData;
    },

    columnsData() {
      if (!this.menuData) return [];

      let cols = [
        { id: 1, items: [] },
        { id: 2, items: [] },
        { id: 3, items: [] },
      ];

      this.menuData.menuItems.forEach((item) => {
        if (item.direction == this.direction && ((this.opType == "sale" && item.sale) || (this.opType == "rent" && item.rent))) {
          cols[item.column - 1].items.push(item);
        }
      });
      return cols;
    },
  },

  data: () => ({
    visible: false,
    menuData: null,
    loadingData: false,
    overflowhidden: true,

    opType: "sale",
    direction: "1",
  }),

  watch: {
    visible(newVal, oldVal) {
      if (newVal && !this.menuData && !this.loadingData) {
        this.loadingData = true;
        axios.get("/api_v2/burger_menu/burger_menu_data/").then((response) => {
          this.menuData = response.data;
        });
      }
    },

    needRender(newVal, oldVal) {
      $("body").toggleClass("expand-left-menu", newVal);
      setTimeout(() => {
          $("body").toggleClass("overflowhidden", newVal);
          this.overflowhidden = !newVal;
        },
        newVal ? 300 : 0
      );
    },
  },

  mounted() {
    $(".v2leftmenu .icon-left-menu").click(() => this.toggle(true));
    $(".v2leftmenu .icon-left-menu-close").click(() => this.toggle(false));
  },

  methods: {
    toggle(visible) {
      this.visible = visible;
    },
  },
};

/*$('.v2leftmenu .icon-left-menu, .v2leftmenu .icon-left-menu-close').click(function(){
        $('body').toggleClass('expand-left-menu');
        var expanded = $('body').hasClass('expand-left-menu');
        setTimeout(function(){
            $('body').toggleClass('overflowhidden', expanded);
            $('.burgerMenu').toggleClass('overflowhidden', !expanded);
        }, expanded ? 300 : 0)
    });

    function updateBurgerMenu() {
        var op = $('.burgerMenu-opTypes .item.current').data('val');
        var direction = $('.burgerMenu-directions .item.current').data('val');
        $('.burgerMenu-page[data-direction=' + direction + '][data-op=' + op + ']').show().siblings().hide()
    }

    $('.burgerMenu-opTypes').on('click', '.item:not(.current)', function () {
        $(this).addClass('current').siblings().removeClass('current')
        updateBurgerMenu();
    });
    $('.burgerMenu-directions').on('click', '.item:not(.current)', function () {
        $(this).addClass('current').siblings().removeClass('current')
        updateBurgerMenu();
    });
    $('.burgerMenu-group-more').on('click', function () {
        $(this).closest('.burgerMenu-group').find('.burgerMenu-item').removeClass('hidden');
    });
    +$('.burgerMenu-group-title').on('click', function () {
        $(this).closest('.burgerMenu-group').toggleClass('collapsed');
    });
    updateBurgerMenu();*/
</script>

<style scoped lang="less">
@import "../common/style.less";


@c: .burgerMenu;

@{c} {
    position: fixed;
    top: 0;
    left: 80px;
    right: 0;
    bottom: 0;
    //background: #111;
    z-index: 1000;
    overflow-y: scroll;
    min-width: 1260px;

    &.preparing, &.overflowhidden {
        overflow: hidden;
    }

    .isvg-logo {
        .rwh-(316, 38);
        position: absolute;
        top: 50px;
        .rfield-(margin-top, -20);
        left: 39px;
        z-index: 1;
    }
}

@{c}-topLinks {
    margin-left: 370px;
    text-align: right;
    padding: 47px 120px 30px 0;

    a {
        display: inline-block;
        .rfont-(bold, 18, 21, Roboto);
        color: white;
        white-space: nowrap;
        border-bottom: 1px solid rgba(151, 151, 151, 0.47);
        text-decoration: none;
        transition: opacity 0.3s, border-color 0.3s;
        margin-left: 39px;
        padding-bottom: 6px;
        margin-bottom: 20px;

        &:hover {
            //opacity: 0.7;
            border-bottom-color: transparent;
        }
    }
}

@{c}-menuContent {
    max-width: 1440px;
    margin: 0 auto;
}

@{c}-opTypes {
    margin: 2px 0 0 39px;
    span {
        .rfont-(500, 24, 30, Roboto);
        color: #444444;
        transition: color 0.3s;
        margin-right: 53px;

        &.current {
            color: white;
        }
        &:not(.current) {
            cursor: pointer;
            &:hover {
                color: #777777;
            }
        }
    }
}

@{c}-directions {
    font-size: 0;
    margin: 43px 0 0 40px;
    display: inline-block;
    border: 1px solid #444444;
    .sep { display: none }

    @media screen and ( max-width: 1500px ) {
        //max-width: 700px;
        & .sep {display: block}
        & .item {
            &:nth-child(3) {
                margin-right: -1px;
                &:after { display:  none }
            }
            &:nth-child(5) {
                margin-left: -1px;
            }

            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                margin-bottom: 0;
                &:before {
                    content: ' ';
                    position: absolute;
                    height: 1px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: #444444;
                    transition: background 0.3s;
                }
            }
        }
    }

    .item {
        .rfont-(500, 14, 50, Roboto);
        color: #CACACA;
        transition: color 0.3s, background 0.3s;
        width: 223px;
        display: inline-block;
        text-align: center;
        margin: -1px 0;
        position: relative;
        text-decoration: none;
        &:first-child {
            margin-left: -1px;
        }
        &:last-child {
            margin-right: -1px;
        }

        &:after {
            content: ' ';
            position: absolute;
            width: 1px;
            top: 0;
            bottom: 0;
            right: -1px;
            background: #444444;
            transition: background 0.3s;
        }
        &:last-child:after {
            background: transparent
        }

        &.current {
            color: black;
            background: white;
            z-index: 1;

            &:after, &:before {
                background: transparent
            }
        }
        &:not(.current) {
            cursor: pointer;
            &:hover {
                color: white;
            }
        }
    }
}

@{c}-page {
    padding: 71px 39px;
    &.preparing {
        visibility: hidden;
    }
    .column {
        width: 33%;
        display: inline-block;
        vertical-align: top;
    }
}

@{c}-group {
    margin-bottom: 35px;
    padding-right: 43px;
    &-title {
        color: white;
        margin-bottom: 19px;
        max-width: 270px;
        //margin-right: 100px;
        .rfont-(500, 18, 25, Roboto);
        cursor: pointer;
        transition: color 0.3s;
        border-bottom: 1px solid transparent;
        position: relative;

        .icon {
            .rwh-(17, 17);
            position: absolute;
            top: 5px;
            right: 0;
            opacity: 0;
            transition: opacity 0.3s;
        }

        &:hover {
            color: #CACACA;
            .icon {
                background-image: url('/static/front/svg/burger-more-hover.svg');
            }
        }
    }
    &.collapsed {
        @{c}-group-items {
            display: none;
        }
        @{c}-group-title {
            border-bottom-color: #444444;

            margin-bottom: -4px;
            padding-bottom: 23px;
            .icon {
                opacity: 1;
            }
        }
        &:hover {
            @{c}-group-title {
                border-bottom-color: #CACACA;
            }
        }
    }
}

@{c}-group-more {
    display: none;
    color: white;
    .rfont-(500, 15, 18, Roboto);
    margin-top: -2px;
    margin-bottom: 2px;

    .icon {
        .rwh-(17, 17);
        position: relative;
        top: 4px;
        margin-left: 8px;
    }

    cursor: pointer;
    transition: color 0.3s;
    &:hover {
        color: #CACACA;
        .icon {
            background-image: url('/static/front/svg/burger-more-hover.svg');
        }
    }

    @{c}-item.hidden ~ & {
        display: inline-block;
    }
}

@{c}-item {
    margin-bottom: 9px;
    a {
        display: inline-block;
        color: #E6E6E6;
        .rfont-(500, 14, 20, Roboto);
        text-decoration: none;
        border-bottom: 1px solid #979797;
        transition: color 0.3s, border-color 0.3s;

        &:hover {
            border-bottom-color: transparent;
            color: white;
        }
    }

    &.hidden {
        display: none;
    }
}

</style>
